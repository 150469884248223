import React from 'react';
import { useHistory } from 'react-router';
import { Footer_container_Home, Image } from './common.styled';
import CoruLogo from '../../assets/img/home/CoruLogo.svg';

function CategoriesFooter() {
  const history = useHistory();

  const date = new Date();
  const anio = date.getFullYear();
  const handleClickAviso = () => {
    history.push('/privacy-notice');
  };

  return (
    <Footer_container_Home
        id="footer_"
        color="#fff"
        backcolor="#2C3337"
        displayM="block"
    >
        <div className="row  card-active" style={{ padding: '2% 3%' }}>
          <div className="col-6" style={{ textAlign: 'left' }}>
            <Image
              src={CoruLogo}
              marginTop="3%"
              className="img-fluid"
              bottom="3%"
              displayMobile="block"
              bottomMObile="none"
              width="92.28px"
            />
          </div>
          <div className="col-6" style={{ textAlign: 'right' }}>
            <p
              onClick={handleClickAviso}
              className="roboto"
              style={{ cursor: 'pointer' }}
            >
              Aviso de privacidad
            </p>
            <p
              onClick={handleClickAviso}
              className="roboto"
              style={{ cursor: 'pointer', marginBottom: '0px' }}
            >
              ©{anio} All Rights Reserved
            </p>
          </div>
        </div>
    </Footer_container_Home>
  );
}

export default CategoriesFooter;
