import styled from "styled-components";
import { imageComponent } from "./common";
import image1 from "../../assets/img/anacoach/CoachImage.png";
import coruLogoGreen from "../../assets/img/home/coruGreen.png";

export const ContentHomeSection = styled.div`
  width: ${(props) => props.width || "45%"};
  margin-left: ${(props) => props.leftD || "10%"};
  margin-right: ${(props) => props.rigthD || " "};
  margin-top: ${(props) => props.topD || " "};
  float: ${(props) => props.floatD || " "};
  display: ${(props) => props.displayD || " "};
  height: ${(props) => props.heightD || " "};
  position: ${(props) => props.pos || " "};
  margin-bottom: ${(props) => props.mbottom || " "};
  border-bottom: ${(props) => props.borderbottom || " "};
  cursor: ${(props) => props.cursor || " "};
  @media (max-width: 768px) {
    width: ${(props) => props.widthM || "100%"};
    margin-left: ${(props) => props.marginLeftM || "0px"};
    margin-right: 0px;
    display: ${(props) => props.displayM || " "};
    height: ${(props) => props.heightM || " "};
    margin-top: ${(props) => props.topDW || " "};
  }

  @media (max-width: 425px) {
    height: ${(props) => props.heighMn425 || " "};
    margin-top: ${(props) => props.topMm320 || "40px "};
  }

  @media (max-width: 320px) {
    width: ${(props) => props.widthMn320 || " "};
    height: ${(props) => props.heighMn320 || " "};
    margin-top: ${(props) => props.topMm320 || " "};
  }
`;
export const ContentHomeSection1 = styled.div`
  width: ${(props) => props.width || "45%"};
  margin-left: ${(props) => props.leftD || "10%"};
  margin-right: ${(props) => props.rigthD || " "};
  margin-top: ${(props) => props.topD || " "};
  float: ${(props) => props.floatD || " "};
  display: ${(props) => props.displayD || " "};
  height: ${(props) => props.heightD || " "};
  position: ${(props) => props.pos || " "};
  margin-bottom: ${(props) => props.mbottom || " "};
  @media (max-width: 700px) {
    width: ${(props) => props.widthM || "100%"};
    margin-left: 0px;
    margin-right: 0px;
    display: ${(props) => props.displayM || " "};
    height: ${(props) => props.heightM || " "};
    margin-top: ${(props) => props.topDW || " "};
  }
  @media (max-width: 691px) {
    height: 300px;
    width: 100%;
    overflow-y: ${(props) => props.OvY || "auto"};
    margin-bottom: 20px;
    align-items: center;
  }
`;

export const Shapes = styled.div`
    position: ${(props) => props.positionD || " "}; 
    width: ${(props) => props.width || "484.74px"}; 
    height: ${(props) => props.heightD || " "};
    left:  ${(props) => props.left || "-13px"};  
    top: ${(props) => props.top || "60px"}; 
    background:  ${(props) => props.background || " "}; 
    display:  ${(props) => props.displayD || " "};
    margin-top:  ${(props) => props.mtopD || " "};
    margin-bottom:  ${(props) => props.mb || ""};
    @media (max-width: 768px) {
        margin-top:  ${(props) => props.mtopM || " "};
        position: ${(props) => props.position || " "}; 
        height: ${(props) => props.height || "788.74px"};
        display:  ${(props) => props.displayM || " "};
        z-index:  ${(props) => props.zindex || " "};
    }  

    } 
       @media (max-width: 480px) {
          height: ${(props) => props.heightD480 || " "};
      
    } 

      @media (max-width: 320px) {
           height: ${(props) => props.heightD320 || " "};
       
      }
`;

export const Rectangle23 = styled.div`
  /*position: fixed;*/
  position: ${(props) => props.position || " "};
  width: ${(props) => props.width || "48.82px"};
  height: ${(props) => props.height || "48.82px"};
  top: ${(props) => props.Ptop || " "};
  opacity: ${(props) => props.opacity || " "};
  left: ${(props) => props.Pleft || " "};
  margin-left: ${(props) => props.Mleft || " "};
  margin-top: ${(props) => props.Mtop || " "};
  background: ${(props) => props.background || "#6BD5AF"};
  transform: ${(props) => props.transform || "rotate(-17.24deg)"};
  display: ${(props) => props.displayD || " "};
  border-radius: ${(props) => props.radius || " "};
  @media (max-width: 768px) {
    position: ${(props) => props.positionMb || " "};
    margin-left: ${(props) => props.leftMb || ""};
    margin-top: ${(props) => props.topMb || " "};
    width: ${(props) => props.widthMb || props.widthM || "48.82px"};
    height: ${(props) => props.heightMb || props.heightM || "48.82px"};
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displayM || " "};
    position: ${(props) => props.positionM || " "};
    margin-left: ${(props) => props.left || "341px"};
    margin-top: ${(props) => props.top || " "};
  }
`;

export const Rectangle25 = styled.div`
  border-radius: ${(props) => props.bradius || " "};
  position: ${(props) => props.positionD || " "};
  left: ${(props) => props.left || " "};
  top: ${(props) => props.top || " "};
  display: ${(props) => props.displayD || " "};
  width: ${(props) => props.width || "32.81px"};
  height: ${(props) => props.height || "32.81px"};
  left: ${(props) => props.left || " "};
  top: ${(props) => props.top || " "};
  display: ${(props) => props.displayD || " "};
  background: ${(props) => props.background || "#BFDD6A"};
  transform: rotate(-35.58deg);
  @media (max-width: 768px) {
    position: ${(props) => props.positionMb || " "};
    margin-left: ${(props) => props.leftMb || ""};
    margin-top: ${(props) => props.topMb || " "};
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displayM || " "};
    position: ${(props) => props.positionM || " "};
    margin-left: ${(props) => props.leftM || "292px;"};
    margin-top: ${(props) => props.topM || "13%"};
  }
`;

export const Rectangle24 = styled.div`
  position: ${(props) => props.positionD || " "};
  left: ${(props) => props.Pleft || " "};
  top: ${(props) => props.Ptop || " "};
  display: ${(props) => props.displayD || " "};
  width: ${(props) => props.width || "55.26px"};
  height: ${(props) => props.height || "55.26px"};
  margin-left: ${(props) => props.left || "98%"};
  margin-top: ${(props) => props.top || "33%"};
  background: ${(props) => props.background || "#91D770"};
  transform: ${(props) => props.transform || "rotate(-17.24deg)"};
  border-radius: ${(props) => props.radius || " "};
  @media (max-width: 700px) {
    width: ${(props) => props.widthM || "55.26px"};
    height: ${(props) => props.heightM || "55.26px"};
    display: ${(props) => props.displayM || " "};
    top: ${(props) => props.PTopM || " "};
    left: ${(props) => props.PLeftM || " "};
    position: ${(props) => props.positionM || " "};
    margin-left: ${(props) => props.mLeftM || "98%"};
    margin-top: ${(props) => props.mTopM || "40%"};
  }
`;

export const Vector1 = styled.div`
  width: ${(props) => props.width || "32.99px"};
  height: ${(props) => props.height || "32.99px"};
  background: ${(props) => props.background || "#76DF44"};
  transform: ${(props) => props.transform || "rotate(45deg)"};
  margin-top: ${(props) => props.top || "9%"};
  border-radius: 9px;
  margin-left: ${(props) => props.left || " "};
  -moz-border-radius: 100% 0 0 0;
  -webkit-border-radius: 100% 0 0 0;
  border-radius: 100% 0 0 0;
  position: ${(props) => props.positionD || " "};
  left: ${(props) => props.Pleft || " "};
  top: ${(props) => props.Ptop || " "};
  display: ${(props) => props.displayD || " "};
  @media (max-width: 700px) {
    display: ${(props) => props.displayM || " "};
    position: ${(props) => props.positionM || " "};
    margin-top: ${(props) => props.top || "9%"};
    margin-left: ${(props) => props.left || " "};
  }
`;

export const Vector2 = styled.div`
  margin-left: ${(props) => props.left || "35%"};
  /* right: 56.5%; */
  /* bottom: 90.35%; */
  background: ${(props) => props.background || "#E3E660"};
  -webkit-transform: ${(props) => props.transform || "rotate(23.75deg)"};
  -ms-transform: ${(props) => props.transform || "rotate(23.75deg)"};
  transform: ${(props) => props.transform || "rotate(23.75deg)"};
  margin-top: ${(props) => props.martop || "0px"};
  width: ${(props) => props.width || "36.81px"};
  height: ${(props) => props.height || "43.02px"};
  border-radius: ${(props) => props.radius || "14px"};
  position: ${(props) => props.positionD || " "};
  left: ${(props) => props.Pleft || " "};
  top: ${(props) => props.Ptop || " "};
  display: ${(props) => props.displayD || " "};
  @media (max-width: 768px) {
    display: ${(props) => props.displayM || " "};
    position: ${(props) => props.positionM || " "};
    margin-left: ${(props) => props.MlefM || "35%"};
    margin-top: ${(props) => props.MtopM || "0px"};
    width: ${(props) => props.widthM || "36.81px"};
    height: ${(props) => props.heightM || "43.02px"};
    left: ${(props) => props.PleftM || " "};
    top: ${(props) => props.PtopM || " "};
  }
`;

export const Vector3 = styled.div`
  position: ${(props) => props.position || " "};
  top: ${(props) => props.Ptop || ""};
  left: ${(props) => props.Pleft || " "};
  margin-left: ${(props) => props.left || "50%"};
  margin-top: ${(props) => props.top || "9.68%"};
  /* right: 24.69%; */
  /* bottom: 89.32%; */
  background: ${(props) => props.background || " #6BD5AF"};
  -webkit-transform: ${(props) => props.transform || "rotate(-57.54deg)"};
  -ms-transform: ${(props) => props.transform || "rotate(-57.54deg)"};
  transform: ${(props) => props.transform || "rotate(-57.54deg)"};
  width: ${(props) => props.width || "57.4px"};
  height: ${(props) => props.height || "57.4px"};
  -moz-border-radius: 100% 0 0 0;
  -webkit-border-radius: 100% 0 0 0;
  border-radius: 100% 0 0 0;
  display: ${(props) => props.displayD || "57.4px"};
  @media (max-width: 768px) {
    display: ${(props) => props.displayM || "57.4px"};
    width: ${(props) => props.widthM || "57.4px"};
    height: ${(props) => props.heightM || "57.4px"};
    top: ${(props) => props.PtopM || ""};
    left: ${(props) => props.PleftM || " "};
  }
`;

export const Title1 = styled.h1`
  position: ${(props) => props.position || " "};
  left: ${(props) => props.left || " "};
  top: ${(props) => props.top || " "};
  height: ${(props) => props.height || " "};
  background: ${(props) => props.background || " "};
  /*position: absolute;*/
  /*
    left: 20px;
    bottom: 592px;
    top: 147px;
    */
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.size || "68px"};
  line-height: 120%;
  letter-spacing: -0.3px;
  color: ${(props) => props.color || "#07210A"};
  margin-left: ${(props) => props.mleft || "0px"};
  margin-right: ${(props) => props.mright || "0px"};
  margin-top: ${(props) => props.mt || "170px"};
  margin-bottom: ${(props) => props.mb || "0px"};
  width: ${(props) => props.width || " "};
  text-align: ${(props) => props.aling || " "};
  padding-bottom: ${(props) => props.pbottom || " "};
  @media (max-width: 768px) {
    position: ${(props) => props.positionM || " "};
    background: ${(props) => props.backgroundM || " "};
    width: ${(props) => props.widthM || props.widthM || "335px"};
    margin-left: ${(props) => props.mleftM || "20px"};
    margin-top: ${(props) => props.mtopM || "90px"};
    margin-bottom: ${(props) => props.mbM || ""};
    font-size: ${(props) => props.sizeM || "50px"};
    height: ${(props) => props.heightM || "200px"};
    left: ${(props) => props.leftM || " "};
    top: ${(props) => props.topM || " "};
    height: ${(props) => props.heightM || " "};
    padding-bottom: ${(props) => props.pbottomM || " "};
  }
`;

export const Subtitle = styled.h3`
  position: ${(props) => props.position || " "};
  top: ${(props) => props.top || " "};
  left: ${(props) => props.left || " "};
  width: ${(props) => props.width || "439px"};
  margin-top: ${(props) => props.marginTop || "25px"};
  margin-left: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: ${(props) => props.weight || "normal"};
  font-size: ${(props) => props.size || "20px"};
  line-height: ${(props) => props.lh || "24px"};
  letter-spacing: -0.2px;
  color: #252525;
  text-align: ${(props) => props.aling || " "};
  margin-bottom: ${(props) => props.mbW || " "};
  @media (max-width: 992px) {
    font-size: ${(props) => props.sizeMm992 || "16px"};
  }
  @media (max-width: 768px) {
    width: ${(props) => props.widthM || props.widthM || "335px"};
    margin-left: ${(props) => props.mleftM || "20px"};
    margin-top: ${(props) => props.mtopM || " "};
    margin-bottom: ${(props) => props.mbottomM || " "};
    font-size: ${(props) => props.sizeM || "16px"};
    height: ${(props) => props.heightM || "44px"};
    font-weight: ${(props) => props.weightM || "normal"};
  }

  @media (max-width: 640px) {
    width: ${(props) => props.widthM || props.widthMm640 || ""};
    text-align: ${(props) => props.alingMm640 || "  "};
    margin-left: ${(props) => props.mleftMm640 || " "};
    font-size: ${(props) => props.sizeM640 || "14px"};
    margin-bottom: ${(props) => props.bottomMm640 || "20px "};
    margin-top: ${(props) => props.mtopM || "10px"};
  }

  /* @media (max-width: 480px) {
          
       width:400px;; 
       font-size: 14px
       text-align:justify; 
       margin-left: 10px;
       margin-top:10px;
       background:red;
    } */

  @media (max-width: 375px) {
    width: ${(props) => props.widthM || props.widthMm360 || ""};
    font-size: ${(props) => props.sizeMm360 || ""};
    text-align: ${(props) => props.alingMm360 || "  "};
    margin-left: ${(props) => props.mleftMm360 || " "};
    margin-top: ${(props) => props.mtopM || "10px"};
    /* background:red; */
  }

  @media (max-width: 360px) {
    width: ${(props) => props.widthM || props.widthMm360 || ""};
    font-size: ${(props) => props.sizeMm360 || ""};
    text-align: ${(props) => props.alingMm360 || "  "};
    margin-left: ${(props) => props.mleftMm360 || " "};
    margin-top: ${(props) => props.mtopM || "10px"};
    /* background:red; */
  }

  @media (max-width: 320px) {
    width: ${(props) => props.widthM || props.widthMm320 || ""};
    font-size: ${(props) => props.sizeMm320 || ""};
    text-align: ${(props) => props.alingMm320 || "  "};
    margin-left: ${(props) => props.mleftMm320 || " "};
    font-size: ${(props) => props.sizeM || "14px"};
    margin-bottom: ${(props) => props.bottomMm320 || " "};
    margin-top: ${(props) => props.mtopM || "10px"};
  }
`;

export const Container1 = styled.div`
  width: 100%;
  /*height: 939px;*/
  overflow: ${(props) => props.ov || "auto"};
  left: 0px;
  top: 0px;
  background: #fafafa;
  overflow-x: ${(props) => props.ovX || "hidden"};
`;

export const Title2 = styled.h1`
  width: ${(props) => props.width || " "};
  height: ${(props) => props.height || " "};
  position: ${(props) => props.position || " "};
  text-align: ${(props) => props.aling || " "};
  margin-left: 0px;
  margin-top: ${(props) => props.topD || "15%"};
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.sizeD || "40px"};
  line-height: 120%;
  letter-spacing: -0.3px;
  color: ${(props) => props.color || "#07210A"};
  @media (max-width: 992px) {
    font-size: ${(props) => props.fSizeMd || "35px;"};
  }
  @media (max-width: 768px) {
    text-align: ${(props) => props.alignM || " "};
    width: ${(props) => props.widthM || "90%;"};
    margin-left: ${(props) => props.mleftm || "20px"};
    font-size: ${(props) => props.sizem || "35px;"};
    margin-top: ${(props) => props.top || "131px"};
  }
`;

export const Subtitle2 = styled.h3`
  position: ${(props) => props.position || ""};
  width: ${(props) => props.width || "476px"};
  height: ${(props) => props.height || "100%"};
  display: ${(props) => props.display || ""};
  margin: ${(props) => props.margin || " "};
  margin-top: ${(props) => props.marginTop || "27px"};
  padding-top: ${(props) => props.paddingTop || " "};
  font-family: Roboto;
  font-style: normal;
  font-weight: ${(props) => props.weight || "normal"};
  font-size: ${(props) => props.size || "16px"};
  line-height: 150%;
  letter-spacing: -0.2px;
  color: ${(props) => props.color || "#252525"};
  margin-bottom: ${(props) => props.bottomD || "0px"};
  cursor: ${(props) => props.cursor || " "};
  text-align: ${(props) => props.aling || ""};
  @media (max-width: 768px) {
    margin-left: ${(props) => props.mlm || "20px"};
    width: ${(props) => props.widthm || "90%"};
    margin-bottom: ${(props) => props.bottom || "25px"};
    margin-top: ${(props) => props.top || "27px"};
  }
`;

export const Rectangle13 = styled.div`
  position: ${(props) => props.position || ""};
  width: ${(props) => props.width || "80%"};
  height: ${(props) => props.height || "80px"};
  left: ${(props) => props.left || "0px"};
  margin: ${(props) => props.margin || " "};
  margin-top: ${(props) => props.top || "0px"};
  background: #ffffff;
  box-shadow: ${(props) => props.boxSha || "0px 2px 48px rgba(0, 0, 0, 0.08)"};
  border-radius: 4px;
  padding: ${(props) => props.padding || " "};
  padding: 12px 17px;
  margin-bottom: ${(props) => props.bottom || "20px"};
  @media (max-width: 768px) {
    width: ${(props) => props.widthm || "90%"};

    font-size: 40px;
    margin-left: ${(props) => props.mleftxs || "20px"};
    display: ${(props) => props.displayM || " "};
    margin-top: ${(props) => props.topM || "0px"};
    margin-bottom: ${(props) => props.bottomM || "20px"};
  }
`;

export const StarCard = styled.div`
  position: absolute;
  width: 347.38px;
  margin-left: 0px;
  margin-top: 30px;
  @media (max-width: 768px) {
    width: 335px;
    margin-left: 20px;
    height: 132px;
  }
`;

export const VectorNum = styled.div`
  /*position: absolute;
        left: 9.87%;
     right: 75.47%; 
        bottom: 53.86%;*/
  background: ${(props) => props.back || "#91D770"};
  width: ${(props) => props.width || "45px"};
  height: ${(props) => props.height || "55.27px"};
  /*top: 14.9%;*/
  border-radius: 34%;
  margin-left: ${(props) => props.marginleft || " "};
  margin-top: ${(props) => props.margintop || " "};
  display: ${(props) => props.display || "flex"};
  transform: ${(props) => props.transform || " "};
  box-shadow: ${(props) => props.shadow || " "};
  float: left;
  cursor: ${(props) => props.cursor || " "};
  @media (max-width: 768px) {
    display: ${(props) => props.displayM || " "};
  }
`;

export const Num = styled.div`
  /*position: absolute; */
  width: 15px;
  /* height: 48px; */
  /* left: 34%; */
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 160%;
  -webkit-letter-spacing: -0.3px;
  -moz-letter-spacing: -0.3px;
  -ms-letter-spacing: -0.3px;
  letter-spacing: -0.3px;
  color: #07210a;
  margin: auto;
`;

export const TextRectangle13 = styled.div`
  /*position: absolute;*/
  height: 55px;
  left: 117px;

  /* Mobile/Body Copy Normal */

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  display: ${(props) => props.display || "flex"};
  align-items: center;
  letter-spacing: -0.2px;

  /* Body Copy Grey */
  margin-left: 74px;
  color: #252525;
  @media (max-width: 768px) {
    /*width: 213px;*/
    height: 55px;
  }
`;

export const RectangleAna = styled.div`
  /* position: fixed; */
  left: 38.4%;
  right: 38.4%;
  top: 45.57%;
  bottom: 51.37%;
  border: 2px solid #bfdd6a;
  box-sizing: border-box;
  border-radius: 35%;
  width: ${(props) => props.width || "87px"};
  height: ${(props) => props.height || "101px;"};
  display: flex;
`;

export const CoachAna1 = styled.div`
  width: ${(props) => props.width || "77.38px"};
  height: ${(props) => props.height || "90.42px"};
  margin: auto;
  background: url(${image1}), url(${image1});
  background-size: cover;
`;

export const ElipseCoach = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  background: #bfdd6a;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin-top: ${(props) => props.marginTop || "-2%"};
  margin-left: ${(props) => props.marginLeft || "5%"};
  @media (max-width: 768px) {
    margin-top: -7%;
    margin-left: ${(props) => props.marginLeftMob || "16%"};
  }
`;
export const ElipseCoach1 = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  background: #bfdd6a;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin-top: ${(props) => props.marginTop || "-2%"};
  margin-left: ${(props) => props.marginLeft || "5%"};
  @media (max-width: 768px) {
    position: relative;
    margin-top: -25%;
    margin-left: 70%;
  }
`;

export const UiCard = styled.div`
  width: 100%;
  display: flex;
  margin: auto;
  margin-top: ${(props) => props.marginTop || "29px"};
  @media (max-width: 768px) {
    margin-top: ${(props) => props.marginTopM || "29px"};
    position: ${(props) => props.positionM || "absolute"};
    height: ${(props) => props.heightM || "195px"};
  }
`;
export const UiCard1 = styled.div`
  width: 100%;
  display: flex;
  margin: auto;
  margin-top: 29px;
  @media (max-width: 768px) {
    position: ${(props) => props.positionM || "relative"};
    height: 300px;
  }
`;

export const Polygon = styled.div`
  margin-left: 42%;
  margin-top: -16%;
  width: 39.84px;
  height: 21px;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom: 20px solid #fff;
  @media (max-width: 768px) {
    position: absolute;
    left: 42%;
    top: ${(props) => props.topMob || "-20%"};
    margin: 0px;
  }
`;

export const LogocoruCoach = styled(imageComponent)`
  position: absolute;
  left: 24%;
  right: 39.2%;
  top: 38.55%;
  bottom: 52.35%;
`;

export const FInalcialProfile = styled.div`
  /*position: absolute;*/
  width: ${(props) => props.width || "91px"};
  height: ${(props) => props.height || "103px"};
  left: 135px;
  margin: auto;
  margin-top: ${(props) => props.top || "40px"};
  @media (min-width: 768px) {
    margin-top: ${(props) => props.topD || "40px"};
  }
  @media (min-width: 555px) {
    margin-top: ${(props) => props.topD2 || "20%"};
  }
`;

export const TextoCoach = styled.p`
  /* position: absolute; */
  width: ${(props) => props.width || "296px;"};
  height: ${(props) => props.height || "100%"};
  font-family: Cabin;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => props.size || "16px"};
  text-align: ${(props) => props.align || " "};
  line-height: 120%;
  -webkit-letter-spacing: -0.3px;
  -moz-letter-spacing: -0.3px;
  -ms-letter-spacing: -0.3px;
  letter-spacing: -0.3px;
  color: #252525;
  margin: ${(props) => props.margin || "auto"};
  margin-top: ${(props) => props.top || ""};
  @media (min-width: 700px) {
    padding-top: 4%;
    margin: ${(props) => props.marginW || "auto"};
  }
`;

export const CTAS = styled.div`
  /*position: absolute;*/
  width: 100%;
  height: 119px;
  margin: auto;
  margin-top: 17px;
  display: flex;
`;

export const ButtonP = styled.button`
  margin-left: auto;
  margin-right: auto;
  /*position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
     Color 2/Pink */
  background: #f64282;
  border-radius: 4px;
  width: 335px;
  height: 48px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  display: flow-root;
  align-items: center;
  text-align: center;
  letter-spacing: -0.3px;

  /* White */

  color: #ffffff;
  margin-top: ${(props) => props.top || " "};
`;

export const InicioSession = styled.div`
  width: 335px;
  height: 30px;
  margin: auto;
  display: flex;
  @media (min-width: 768px) {
    height: 90px;
  }
`;

export const ContainerMenu = styled.div``;

export const ContainerSubMenu = styled.div`
  height: ${(props) => props.hm || " "};
  width: ${(props) => props.w || "100%"};
  padding-top: ${(props) => props.pt || ""};
  padding-left: ${(props) => props.pl || ""};
  padding-bottom: ${(props) => props.pb || ""};
  background: ${(props) => props.bc || ""};
  position: absolute;
`;

export const TextSession = styled.p`
  margin: auto;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: -0.3px;
  color: #07210a;
  text-decoration: underline;
`;

export const ContainerBottons = styled.div`
  width: ${(props) => props.widthM || "90%"};
  display: flex;
  height: ${(props) => props.height || "100%"};
  position: ${(props) => props.position || "absolute"};
  top: ${(props) => props.topM || " "};
  left: ${(props) => props.leftM || " "};
  margin: ${(props) => props.margin || "auto"};
  margin-top: ${(props) => props.top || ""};
  display: ${(props) => props.displayM || " "};
  margin-bottom: ${(props) => props.bottomM || " "};
  margin-left: ${(props) => props.left || " "};
  margin-right: ${(props) => props.right1 || " "};
  text-align: ${(props) => props.aling || " "};
  @media (max-width: 992px) {
    width: ${(props) => props.widthMd || props.widthD};
    height: ${(props) => props.heightMd || "100%"};
  }
  @media (min-width: 768px) {
    width: ${(props) => props.widthD || "220px"};
    margin-left: ${(props) => props.leftD || " "};
    float: ${(props) => props.floatD || " "};
    display: ${(props) => props.displayD || " "};
    margin-top: ${(props) => props.topD || " "};
    margin-bottom: ${(props) => props.bottomD || " "};
    top: ${(props) => props.top || " "};
    left: ${(props) => props.left || " "};
  }

  @media (max-width: 980px) {
    margin-left: ${(props) => props.leftmd || " "};
  }

  @media (max-width: 360px) {
    width: ${(props) => props.widthM || props.widthMm360 || ""};
    margin-left: ${(props) => props.mleftMm360 || " "};

    /* background:red; */
  }

  @media (max-width: 320px) {
    width: ${(props) => props.widthM || props.widthMm320 || ""};
    margin-left: ${(props) => props.mleftMm320 || " "};
    margin-bottom: ${(props) => props.bottomMm320 || " "};
  }
`;

export const Rombo = styled.div`
  /*position: absolute;*/
  width: ${(props) => props.width || "26.84px"};
  height: ${(props) => props.height || "26.84px"};
  /* left: 44.01px; */
  /* top: 803.99px; */
  background: ${(props) => props.background || "#44DFA7"};
  -webkit-transform: ${(props) => props.transform || " rotate(-45deg)"};
  -ms-transform: ${(props) => props.transform || " rotate(-45deg)"};
  transform: ${(props) => props.transform || " rotate(-45deg)"};
  margin-left: ${(props) => props.marginleft || "44.01px"};
  margin-top: ${(props) => props.margintop || " -3%"};
  z-index: ${(props) => props.index || " "};
  position: ${(props) => props.position || " "};
  display: ${(props) => props.displayD || " "};
  @media (max-width: 700px) {
    display: ${(props) => props.displayM || " "};
  }
`;

export const Gradient = styled.div`
  position: absolute;
  width: 375px;
  height: 109px;
  left: 0px;
  top: 830px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;

export const Rectangle20 = styled.div`
  /*position: absolute;*/
  width: ${(props) => props.width || "101.27px"};
  height: ${(props) => props.height || "101.27px"};
  margin-left: ${(props) => props.marginleft || "341px"};
  background: ${(props) => props.background || "#BFDD6A"};
  transform: ${(props) => props.transform || "rotate(-17.24deg)"};
  margin-top: ${(props) => props.margintop || " "};
  display: ${(props) => props.displayD || " "};
  @media (max-width: 700px) {
    display: ${(props) => props.displayM || " "};
  }
`;

export const Seccion2 = styled.div`
  width: 100%;
  min-height: 440.44px;
  margin-bottom: 12.69px;
  @media (max-width: 700px) {
    min-height: auto;
    margin-bottom: 25.4px;
  }
`;

export const TitleEntrenamientos = styled.p`
  /*position: absolute;*/
  width: 90%;
  min-height: ${(props) => props.minheight || "137px"};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.3px;
  color: #07210a;
  text-align: ${(props) => props.alingM || " "};
  margin-top: ${(props) => props.top || " "};
  display: ${(props) => props.displayM || " "};
  @media (min-width: 480px) {
    width: ${(props) => props.widthD || "100%"};
    min-height: 100px;
    text-align: ${(props) => props.aling || " "};
    margin-top: ${(props) => props.topD || " "};
    display: ${(props) => props.displayD || " "};
  }
  @media (min-width: 700px) {
    max-width: ${(props) => props.minWindthweb || " "};
    min-height: 100px;
    margin-left: ${(props) => props.marginleftweb || " "};
  }
`;

export const SubTexto = styled.p`
  /*position: absolute;*/
  width: 90%;
  height: ${(props) => props.height || "67px"};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => props.top || "21px"};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.2px;
  color: #252525;
  margin-bottom: ${(props) => props.bottom || "10px"};
  @media (min-width: 480px) {
    width: 100%;
    min-height: ${(props) => props.heightD || "100px"};
    text-align: ${(props) => props.alingD || " "};
  }
  @media (max-width: 470px) {
    height: ${(props) => props.heightmb1 || ""};
  }
`;

export const DivLogos = styled.div`
  width: 90%;
  height: 301px;
  margin: auto;
  margin-bottom: 59px;
  @media (min-width: 480px) {
    margin-top: 20%;
    width: 80%;
    height: auto;
  }
`;

export const DivTrofeos = styled.div`
  /*width: 90%;*/
  height: 323.34px;
  margin-bottom: 89.66px;
  margin-top: 0%;
  @media (min-width: 700px) {
    display: none !important;
  }
`;

export const DivLogo = styled.div`
  width: 24.45px;
  height: 13.55px;
  margin: auto;
  margin-top: -55%;
  margin-left: 47%;
  background: url(${coruLogoGreen}), url(${coruLogoGreen});
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
`;

export const SocialMedia = styled.div`
  width: 421.3px;
  margin-bottom: 0%;
  /*height: 401.5px;*/
`;

export const Triangle = styled.div`
  border-right: ${(props) => props.widthborder || "35px solid transparent"};
  border-top: ${(props) => props.widthborder || "35px solid transparent"};
  border-left: ${(props) => props.widthborder || " 35px solid transparent"};
  border-bottom: ${(props) => props.widthborderColor || "50px solid #91D770;"};
  width: ${(props) => props.width || "35px"};
  height: ${(props) => props.height || "35px"};
  margin-left: ${(props) => props.marginleft || "-8.5px"};
  margin-top: ${(props) => props.margintop || ""};
  transform: ${(props) => props.transform || "rotate(30deg)"};
`;

export const WhatsappDiv = styled.div`
  background-color: #25d366;
  height: 62px;
  width: 62px;
  border-radius: 50%;
  bottom: 65px;
  right: 20px;
  /*left: 20px;*/
  margin-bottom: 20px;
  position: fixed;
  z-index: 3000;
  box-shadow: 0px 0px 5px 2px #eeeeee;
  display: flex;
`;

export const WhatsappR = styled.a`
  color: #ffff;
  font-size: 26px;
  margin: auto;
`;

export const BtnPlay = styled.button`
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: #fb245c;
  opacity: 0.5;
  border: 1px solid #fb245d;
  display: ${(props) => props.displayD || " "};
  @media (max-width: 700px) {
    display: ${(props) => props.displayMobile || " "};
  }
`;

export const AsesorDiv = styled.div`
  background-color: #fff;
  height: ${(props) => props.height || "66px"};
  width: ${(props) => props.width || "62px"};
  border-radius: ${(props) => props.radius || "40%"};
  bottom: 65px;
  right: 30px;
  margin-bottom: 20px;
  position: fixed;
  z-index: 3000;
  box-shadow: 0px 0px 5px 2px #eeeeee;
  display: ${(props) => props.display || "flex"};
`;

export const CookiesNoticeFragment = styled.div`
  width: 100%;
  background: rgb(166, 166, 166);
  color: #07210a;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  height: 95px;
  ${(props) => (props.display !== "" ? `display: ${props.display};` : "")}
  @media (max-width: 480px) {
    height: 150px;
  }
`;

export const CookiesNoticeContainer = styled.div`
  width: 80%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  padding: 15px 0;
  justify-content: space-around;
  @media (max-width: 480px) {
    width: 95%;
  }
`;

export const CookiesNoticeImage = styled.img`
  max-width: 48px;
  heigth: auto;
  display: ${(props) => (props.displayDesk !== "" ? props.displayDesk : "")};
  @media (max-width: 480px) {
    display: ${(props) => (props.displayM !== "" ? props.displayM : "")};
    margin-top: 1rem;
  }
`;

export const CookiesNoticeText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  width: 80%;
  padding: 15px 0;
  @media (max-width: 1040px) {
    width: 90%;
    overflow-y: scroll;
    padding-top: 8.5rem;
    padding-bottom: 0.5rem;
    margin-right: 0.5rem;
    margin-left: 0;
  }
`;

export const CookiesNoticeAcceptButton = styled.button`
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  border: none;
  padding: 5px 9px;
  background-color: #f64282;
  color: white;
  border-radius: 4px;
  font-weight: bold;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const CookiesNoticeColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardTarjetasOpciones = styled.div`
  width: ${(props) => props.width || "363px"};
  height: ${(props) => props.height || "467px"};
  background: ${(props) => props.bg || "#ffffff !important"};
  box-shadow: 2px 2px 32px rgba(0, 0, 0, 0.25);
  margin-bottom: 2rem;
  border: ${(props) => props.border || ""};
  @media (max-width: 480px) {
    max-width: ${(props) => props.maxWidthM || "90%"};
  }
`;

export const CardTarjetasOpcionesHeader = styled.div`
  height: ${(props) => props.height || "60px"};
  width: ${(props) => props.width || "100%"};
  background: ${(props) => props.bg || "#A370FF !important"};
  color: ${(props) => props.color || "#ffffff !important"};
  padding: ${(props) => props.padding || ""};
  align-items: center;
  display: ${(props) => props.display || "flex"};
  flex-direction: row;
  visibility: ${(props) => props.vis || ""};
`;

export const CardTarjetasOpcionesContainer = styled.div`
  padding: ${(props) => props.padding || "10px"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  display: flex;
  flex-direction: column;
`;

export const CardTarjetasOpcionesCardInformation = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "20%"};
  justify-content: space-between;
  align-items: flex-end;
`;

export const CardTarjetasOpcionesImagen = styled.img`
  height: ${(props) => props.height || "100%"};
  width: ${(props) => props.width || "auto"};
  transform: ${(props) => props.transform || ""};
`;

export const CardTarjetasOpcionesAnualidadContainer = styled.div`
  width: ${(props) => props.width || "fit-content"};
  text-align: right;
  display: flex;
  flex-direction: column;
`;

export const CardTarjetasOpcionesList = styled.ul`
  width: ${(props) => props.width || "100%"};
  max-width: ${(props) => props.mWidth || " "};
  height: ${(props) => props.height || "33%"};
  color: ${(props) => props.color || "black"};
  margin-top: ${(props) => props.mtop || ""};

  @media (max-width: 320px) {
    margin-bottom: 10px;
  }
`;

export const ResponsiveDiv = styled.div`
  height: ${(props) => props.height || " "};
  width: ${(props) => props.width || " "};
  @media (max-width: 480px) {
    height: ${(props) => props.heightM || " "};
    width: ${(props) => props.widthM || " "};
    margin-left: ${(props) => props.mlM || "auto"};
    margin-right: ${(props) => props.mrM || "auto"};
  }
`;

export const ContainerContentStep = styled.div`
  background: ${(props) => props.bg || "#a6a6a61f"};
  width: ${(props) => props.width || "412px"};
  height: ${(props) => props.height || "fit-content"};
  color: ${(props) => props.color || "#07210A"};
  border-radius: ${(props) => props.bradius || "4px"};
  padding: ${(props) => props.padding || "15px"};
  margin-left: ${(props) => props.ml || ""};
  margin-right: ${(props) => props.mr || ""};
  margin-top: ${(props) => props.mt || "20px"};
  ${(props) => props.display && `display: ${props.display};`}
  @media (max-width: 768px) {
    width: ${(props) => props.width || "100%"};
  }
`;

export const ContentStepContainerOption = styled.div`
  display: ${(props) => props.display || "flex"};
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: space-between;
  width: 287px;
  align-items: center;
  margin: auto;
`;

export const ContainerContentLabel = styled.label`
  font-size: ${(props) => props.fsize || "14px"};
  margin-right: ${(props) => props.mright || "auto"};
`;

export const StoriCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: ${(props) => props.width || "70%"};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => props.mtop || "20px"};
  flex-wrap: wrap;
  overflow: hidden;
  @media (max-width: 480px) {
    padding: ${(props) => props.paddingM || "20px"};
    width: ${(props) => props.widthM || "90%"};
    display: none;
  }

  @media (max-width: 320px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    display: none;
  }
`;

export const StoriCard = styled.div`
  width: ${(props) => props.width || "100%"};
  max-width: ${(props) => props.mWidth || ""};
  height: ${(props) => props.height || "100%"};
  max-height: ${(props) => props.mHeight || " "};
  background: ${(props) => props.bg || "#fff"};
  box-shadow: ${(props) => props.bsh || "0px 2px 32px rgba(0, 0, 0, 0.25)"};
  padding: 20px;
  border-radius: ${(props) => props.bradius || "4px"};
  margin-bottom: ${(props) => props.mbottom || "20px"};
  margin-top: ${(props) => props.mtop || "20px"};

  @media (max-width: 480px) {
    margin-top: ${(props) => props.mtopM || "20px"};
    margin-left: ${(props) => props.mleftM || "20px"};
    margin-right: ${(props) => props.mrightM || "20px"};
    height: 600px;
  }

  @media (max-width: 425px) {
    height: 500px;
  }
  @media (max-width: 375px) {
    height: 500px;
  }

  @media (max-width: 360px) {
    height: 500px;
  }

  @media (max-width: 320px) {
    height: 550px;
  }
`;

export const ContainerText = styled.div`
  @media (max-width: 320px) {
    margin-top: 30px;
  }
`;

export const ContainerText2 = styled.div`
  @media (max-width: 320px) {
    margin-top: 0px;
  }
`;

export const StoriCardContainerCarousel = styled.div`
            display:none;
           
    
     @media (max-width: 480px) {
               display:flex;
               padding:0px
               width:90%;
               margin:0px;
               height:650px;
      .rec-pagination {
       /* background:red; */
       margin-top:0px !important;
       margin-bottom:0px !important;
      }
              
    }

     @media (max-width: 375px) {
               display:flex;
               padding:0px
               width:90%;
               margin:0px;
               height:580px;
               background:red;
      .rec-pagination {
       /* background:red; */
       margin-top:0px !important;
       margin-bottom:0px !important;
      }
              
    }



     @media (max-width: 360px) {
               display:flex;
               padding:0px
               width:90%;
               margin:0px;
               height:550px;
               background:red;
      .rec-pagination {
       /* background:red; */
       margin-top:0px !important;
       margin-bottom:0px !important;
      }
              
    }

     @media (max-width: 320px) {
               display:flex;
               padding:0px
               width:90%;
               margin:0px;
               height:595px;
               
      .rec-pagination {
       /* background:red; */
       margin-top:0px !important;
       margin-bottom:0px !important;
      }
              
    }


`;

export const ContainerBeneficios = styled.div`
  @media (min-width: 320px) {
    margin: 10px;
    height: auto;
  }

  @media (max-width: 425px) {
    margin: 25px;
    height: auto;
  }
`;
export const ContainerMiniCard = styled.img`
  display: none;

  @media (max-width: 320px) {
    width: 40%;
    display: block;
  }
  @media (max-width: 425px) {
    width: 40%;
    margin-left: 100px;
    display: block;
    margin-bottom: 15px;
  }
`;

export const Triangu = styled.div`
  border-right: ${(props) => props.widthborder || "35px solid transparent"};
  border-top: ${(props) => props.widthborder || "35px solid transparent"};
  border-left: ${(props) => props.widthborder || " 35px solid transparent"};
  border-bottom: ${(props) => props.widthborderColor || "50px solid #91D770;"};
  width: ${(props) => props.width || "35px"};
  height: ${(props) => props.height || "35px"};
  margin-left: ${(props) => props.marginleft || "-8.5px"};
  margin-top: ${(props) => props.margintop || ""};
  transform: ${(props) => props.transform || "rotate(30deg)"};
  position: ${(props) => props.position || "relative"};
  @media (max-width: 480px) {
    border-right: ${(props) => props.widthborderM || "25px solid transparent"};
    border-top: ${(props) => props.widthborderM || "25px solid transparent"};
    border-left: ${(props) => props.widthborderM || " 25px solid transparent"};
    border-bottom: ${(props) =>
      props.widthborderColorM || "30px solid #91D770"};
  }
`;
