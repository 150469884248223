/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  DivLoader,
  Images,
  TrainingQuestion,
  TrainingText,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import {
  GET_PRODUCTS_BY_IDTRAINING_AND_NAME,
  RECOVERY_ROUTINE_BY_ORDER,
} from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import loader from '../../assets/img/anacoach/loading.svg';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';

const Convivencia = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  data,
  setDescIntro,
  extraIncomeProducts,
  setExtraIncomeProducts,
}) => {
  const productNames = [
    'AppJobs',
    'OCC Mundial',
    'Indeed',
    'JobisJob',
    'Computrabajo',
    'Jooble',
    'Lifepoints',
    'Yougov',
    'Mobrog',
    'TyA Consultores',
    'The Panel Station',
    'My Iyo',
    'GreenPanthera',
    'Ysense',
    'Encuenstas Survey Work',
    'Surveypronto',
    'Livra',
    'Marketagent',
    'iSurveyWorld',
    'Segunda mano',
    'Kichink',
    'Mercado Libre',
    'Gotrendier',
    'Marketplace',
  ];

  const [productsData, setProductsData] = useState([]);

  const {
    loading,
    error,
    data: prodData,
  } = useQuery(GET_PRODUCTS_BY_IDTRAINING_AND_NAME, {
    variables: {
      idTraining: parseInt(currentTraining),
      names: productNames,
    },
    onCompleted({ getProductsByIdTrainingAndName }) {
      const products = JSON.parse(getProductsByIdTrainingAndName.response);
      setProductsData(products);
    },
    onError(error) {
      console.log('error', error);
    },
  });

  if (loading) {
    return (
      <div>
        <DivLoader
          ta="left"
          margin="0 20px 0 0"
          ml="25%"
          mlw="15%"
          wi="110px"
          he="32px"
        >
          <Images
            src={loader}
            top="-32px"
            topweb=""
            left="2px"
            leftw="0"
            width="95px"
            position="absolute"
            className="img-fluid rotate180"
          ></Images>
          {/* <CircularProgress color="inherit" /> */}
        </DivLoader>
        <DivLoader
          bcolor="transparent"
          ta="left"
          margin="0 20px 0 0"
          ml="25%"
          mlw="15%"
          wi="110px"
          he="32px"
        >
          <TrainingText
            size="11px"
            sizew="12px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.2px"
            color="#A6A6A6"
          >
            Escribiendo...
          </TrainingText>
        </DivLoader>
      </div>
    );
  }
  if (error) return `Error: ${error}`;

  return (
    <ConvivenciaComponent
      Date={Date}
      hora={hora}
      orderRutine={orderRutine}
      setOrderRutine={setOrderRutine}
      currentTraining={currentTraining}
      createJsonInput={createJsonInput}
      questions={questions}
      setQuestions={setQuestions}
      currentRoutine={currentRoutine}
      nextOrderRoutine={nextOrderRoutine}
      trainingColor={trainingColor}
      data={productsData}
      setDescIntro={setDescIntro}
      extraIncomeProducts={extraIncomeProducts}
      setExtraIncomeProducts={setExtraIncomeProducts}
    />
  );
};

const ConvivenciaComponent = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  data,
  setDescIntro,
  extraIncomeProducts,
  setExtraIncomeProducts,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  // const [preguntasExtraIncomeConvivencia, setPreguntasExtraIncomeConvivencia] = useState(sessionStorage.getItem('PreguntasExtraIncomeConvivencia') || '');

  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');

  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [primeraPreguntaConvivencia, setPrimeraPreguntaConvivencia] = useState(
    sessionStorage.getItem('PrimeraPreguntaConvivencia') || '',
  );
  const [segundaPreguntaConvivencia, setSegundaPreguntaConvivencia] = useState(
    sessionStorage.getItem('SegundaPreguntaConvivencia') || '',
  );

  const [showSegundaPreguntaConvivencia, setShowSegundaPreguntaConvivencia] = useState(true);

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editQuestOne, setEditQuestOne] = useState('');
  const [editQuestTwo, setEditQuestTwo] = useState('');

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const notSecondQuestion = () => {
    const idValue = Rutine.questions[1].detailQuestion[1].FieldOption;
    const questionN = Rutine.questions[1].QuestionsOrder;
    const value = Rutine.questions[1].detailQuestion[1].CatFieldDetail_IDCatFieldDetail;

    questions.push({
      id: idValue,
      question: questionN,
      value,
    });

    createJsonInput(Rutine, questions);
  };

  const handleChangeCatOption = (val) => {
    let products = [];
    switch (val.step) {
      case 0:
        setEditQuestOne(true);
        setPrimeraPreguntaConvivencia(val.value);
        if (val.value !== 'Tengo alma de vendedor') {
          setShowSegundaPreguntaConvivencia(false);
          if (
            val.value
            === 'Me gusta hacer manualidades creativas como pintar, cocinar, dibujar, hacer postres, etc.'
          ) {
            products = [];
            setDescIntro(
              'Si lo tuyo es cocinar, preparar postres o hacer alguna manualidad puedes emprender un pequeño negocio en tu casa y ofrecer tus productos entre amigos, vecinos y conocidos.',
            );
          } else if (
            val.value === 'Me adapto bien a cualquier entorno y aprendo rápido'
          ) {
            products = data.filter(
              (e) => e.Name.startsWith('AppJobs')
                || e.Name.startsWith('OCC Mundial')
                || e.Name.startsWith('Indeed')
                || e.Name.startsWith('JobisJob')
                || e.Name.startsWith('Computrabajo')
                || e.Name.startsWith('Jooble'),
            );
            setDescIntro(
              'Si eres de las personas que no pueden estar quietas en su tiempo libre, ocúpate con un empleo de medio tiempo y gana dinero extra. Revisa los empleos temporales o fijos que ofrecen las siguientes páginas web:',
            );
          } else if (
            val.value
            === 'Visitar diferentes lugares (restaurantes, cines, tiendas, etc) y dar mi opinión'
          ) {
            products = data.filter(
              (e) => e.Name.startsWith('Lifepoints')
                || e.Name.startsWith('Yougov')
                || e.Name.startsWith('Mobrog')
                || e.Name.startsWith('TyA Consultores')
                || e.Name.startsWith('The Panel Station')
                || e.Name.startsWith('My Iyo')
                || e.Name.startsWith('GreenPanthera')
                || e.Name.startsWith('Ysense')
                || e.Name.startsWith('Encuenstas Survey Work')
                || e.Name.startsWith('Surveypronto')
                || e.Name.startsWith('Livra')
                || e.Name.startsWith('Marketagent')
                || e.Name.startsWith('iSurveyWorld'),
            );
            setDescIntro(
              'Gana dinero dando tu opinión acerca de distintos lugares y servicios que te brindan en tiendas, restaurantes, entretenimiento y más: sé un mystery shooper. Regístrate aquí:',
            );
          }
          setExtraIncomeProducts(products);
          notSecondQuestion();
        }
        gsap.to(window, { duration: 1, scrollTo: '#activeConvivencia2' });
        break;
      case 1:
        setEditQuestTwo(true);
        setSegundaPreguntaConvivencia(val.value);
        if (
          val.value
          === 'Tienes muchos artículos que ya no usas, la mayoría nuevos o en buen estado'
        ) {
          products = data.filter(
            (e) => e.Name.startsWith('Segunda mano')
              || e.Name.startsWith('Kichink')
              || e.Name.startsWith('Mercado Libre')
              || e.Name.startsWith('Gotrendier')
              || e.Name.startsWith('Marketplace'),
          );
          setDescIntro(
            'Haz espacio en tu casa, libérate de las cosas que ya no usas y gana dinero. Puedes vender tus artículos a través de:',
          );
        } else if (
          val.value
          === 'Te gusta recomendar productos y servicios a tus amigos y familiares'
        ) {
          products = data.filter((e) => e.Name.startsWith('Curadeuda'));
          setDescIntro(
            'Si buscas nuevas formas de ganar dinero sin invertir mucho, inscríbete a un programa de afiliados, donde podrás recomendar a tus amigos y familiares productos o servicios y ganar dinero por ello.',
          );
        }
        setExtraIncomeProducts(products);
        gsap.to(window, { duration: 1, scrollTo: '#activeConvivencia3' });
        break;
      default:
        // console.log('default');
        break;
    }
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          let contResponse = 0;
          const stopRoutine = false;

          const answers = {
            1: '',
            2: '',
          };

          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionOrder } = arrayResponseRutine[i];
              const { questionType } = arrayResponseRutine[i];

              if (responseUser && responseUser !== '') {
                answers[questionOrder] = responseUser;
              }

              if (questionType === 'Cat') {
                const { detailQuestion } = routines[idRoutine].questions[i];
                for (let x = 0; x < detailQuestion.length; x++) {
                  if (detailQuestion[x].FieldOption === responseUser) {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              } else {
                contResponse++;
                sessionStorage.setItem(
                  arraySessionsRutines[idTypeQuestion],
                  responseUser,
                );
              }
            }

            // ---------

            let products = [];

            if (answers[1] !== '') {
              setEditQuestOne(true);
              setPrimeraPreguntaConvivencia(answers[1]);
              if (answers[1] !== 'Tengo alma de vendedor') {
                setShowSegundaPreguntaConvivencia(false);
                if (
                  answers[1]
                  === 'Me gusta hacer manualidades creativas como pintar, cocinar, dibujar, hacer postres, etc.'
                ) {
                  products = [];
                  setDescIntro(
                    'Si lo tuyo es cocinar, preparar postres o hacer alguna manualidad puedes emprender un pequeño negocio en tu casa y ofrecer tus productos entre amigos, vecinos y conocidos.',
                  );
                } else if (
                  answers[1]
                  === 'Me adapto bien a cualquier entorno y aprendo rápido'
                ) {
                  products = data.filter(
                    (e) => e.Name.startsWith('AppJobs')
                      || e.Name.startsWith('OCC Mundial')
                      || e.Name.startsWith('Indeed')
                      || e.Name.startsWith('JobisJob')
                      || e.Name.startsWith('Computrabajo')
                      || e.Name.startsWith('Jooble'),
                  );
                  setDescIntro(
                    'Si eres de las personas que no pueden estar quietas en su tiempo libre, ocúpate con un empleo de medio tiempo y gana dinero extra. Revisa los empleos temporales o fijos que ofrecen las siguientes páginas web:',
                  );
                } else if (
                  answers[1]
                  === 'Visitar diferentes lugares (restaurantes, cines, tiendas, etc) y dar mi opinión'
                ) {
                  products = data.filter(
                    (e) => e.Name.startsWith('Lifepoints')
                      || e.Name.startsWith('Yougov')
                      || e.Name.startsWith('Mobrog')
                      || e.Name.startsWith('TyA Consultores')
                      || e.Name.startsWith('The Panel Station')
                      || e.Name.startsWith('My Iyo')
                      || e.Name.startsWith('GreenPanthera')
                      || e.Name.startsWith('Ysense')
                      || e.Name.startsWith('Encuenstas Survey Work')
                      || e.Name.startsWith('Surveypronto')
                      || e.Name.startsWith('Livra')
                      || e.Name.startsWith('Marketagent')
                      || e.Name.startsWith('iSurveyWorld'),
                  );
                  setDescIntro(
                    'Gana dinero dando tu opinión acerca de distintos lugares y servicios que te brindan en tiendas, restaurantes, entretenimiento y más: sé un mystery shooper. Regístrate aquí:',
                  );
                }
                setExtraIncomeProducts(products);
              }
              gsap.to(window, { duration: 1, scrollTo: '#activeConvivencia2' });
            }

            if (answers[2] !== '' && answers[1] === 'Tengo alma de vendedor') {
              setEditQuestTwo(true);
              setSegundaPreguntaConvivencia(answers[2]);
              if (
                answers[2]
                === 'Tienes muchos artículos que ya no usas, la mayoría nuevos o en buen estado'
              ) {
                products = data.filter(
                  (e) => e.Name.startsWith('Segunda mano')
                    || e.Name.startsWith('Kichink')
                    || e.Name.startsWith('Mercado Libre')
                    || e.Name.startsWith('Gotrendier')
                    || e.Name.startsWith('Marketplace'),
                );
                setDescIntro(
                  'Haz espacio en tu casa, libérate de las cosas que ya no usas y gana dinero. Puedes vender tus artículos a través de:',
                );
              } else if (
                answers[2]
                === 'Te gusta recomendar productos y servicios a tus amigos y familiares'
              ) {
                products = data.filter((e) => e.Name.startsWith('Curadeuda'));
                setDescIntro(
                  'Si buscas nuevas formas de ganar dinero sin invertir mucho, inscríbete a un programa de afiliados, donde podrás recomendar a tus amigos y familiares productos o servicios y ganar dinero por ello.',
                );
              }
              setExtraIncomeProducts(products);
              gsap.to(window, { duration: 1, scrollTo: '#activeConvivencia3' });
            }

            if (
              arrayResponseRutine.length <= routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  const handleChangeIcon = (json) => {
    setEditQuestOne(false);
    setEditQuestTwo(false);
    setExtraIncomeProducts(null);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      if (orderRutine === currentRoutine) {
        gsap.to(window, { duration: 1, scrollTo: '#activeConvivencia' });
      }
    }, 4000);
  }, []);

  return (
    <Fragment>
      {!loaderWait || orderRutine !== 6 ? (
        <>
          {/* pregunta número uno */}
          <div id="activeConvivencia"></div>
          <QuestionsOrder
            classCard={'cardOne'}
            orderRutine={currentRoutine}
            step={0}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            className={
              (primeraPreguntaConvivencia === ''
                || (lapiz[0].routine === 6 && lapiz[0].active === 1))
              && editQuestOne !== true
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="activeConvivencia2"></div>
          <CardTwo
            classCard={
              primeraPreguntaConvivencia !== ''
              && (editQuestOne === true
                || !(lapiz[0].routine === 6 && lapiz[0].active === 1))
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={primeraPreguntaConvivencia}
            colorTraining={trainingColor}
          >
            <img
              alt="editIcon"
              className={
                primeraPreguntaConvivencia !== ''
                && segundaPreguntaConvivencia === ''
                && showSegundaPreguntaConvivencia
                  ? 'iconEditCommon'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 6, active: 1 })}
            />
          </CardTwo>
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
      {/* pregunta número dos */}
      <QuestionsOrder
        classCard={
          primeraPreguntaConvivencia !== ''
          && showSegundaPreguntaConvivencia
          && (editQuestOne === true
            || !(lapiz[0].routine === 6 && lapiz[0].active === 1))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={1}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((primeraPreguntaConvivencia !== ''
            && segundaPreguntaConvivencia === ''
            && showSegundaPreguntaConvivencia)
            || (lapiz[0].routine === 6 && lapiz[0].active === 2))
          && (!(lapiz[0].routine === 6 && lapiz[0].active === 1)
            || editQuestOne === true)
          && editQuestTwo !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="1-1"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeConvivencia3"></div>
      <CardTwo
        classCard={
          segundaPreguntaConvivencia !== ''
          && showSegundaPreguntaConvivencia
          && (editQuestTwo === true
            || !(lapiz[0].routine === 6 && lapiz[0].active === 2))
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={segundaPreguntaConvivencia}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={
            segundaPreguntaConvivencia !== '' && showSegundaPreguntaConvivencia
              ? 'iconEditCommon'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 6, active: 2 })}
        />
      </CardTwo>
    </Fragment>
  );
};

export default Convivencia;
