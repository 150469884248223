import React from "react";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { Box, Typography } from "@mui/material";

const DateInput = ({ hasSelectedDate, onClick, textInput }) => (
    <Box
        height="44px"
        bgcolor="white"
        padding="12px 16px"
        display="flex"
        justifyContent="space-between"
        sx={{
            borderBottom: "1px solid #959595",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            cursor: "pointer",
        }}
        onClick={onClick}
    >
        <Typography color={hasSelectedDate ? "#212529" : "#959595"}>
            {hasSelectedDate ? textInput : "Fecha y Hora"}
        </Typography>
        <CalendarTodayIcon
            htmlColor="rgba(76, 78, 100, 0.54)"
            style={{ width: "20px", height: "22px" }}
        />
    </Box>
);

export default DateInput;
