import React from 'react';
import Carousel from 'react-elastic-carousel';
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';
import { CREATE_OPPORTUNITIES } from '../../mutations';
import loader from '../../assets/img/anacoach/loading.svg';
import stars from '../../assets/img/anacoach/5Stars.png';
import ClickButton from '../../Helpers/HookHelpers';
import {
  ImageSections,
  TrainingText,
  TrainingSectionFlex,
  Images,
  CardsBtn,
  DivFlex,
  DivStyle,
  DivGray,
  Resultado,
  DivLoader,
  CardsTxt,
} from '../../assets/styles/Training.styled';
import { Col12 } from './common.styled';

const RpProductsWithStars = ({ products = [] }) => {
  const { actions } = ClickButton();
  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        sessionStorage.setItem('datProducts', response.idProducts);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const formatterMx = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    const arr = number.toString().split('.');
    arr[0] = arr[0].replace(exp, rep);
    return arr[1] ? arr.join('.') : arr[0];
  };

  const generateOpportunities = (product) => {
    const click = `ComenzarEntrenamiento_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 2,
        idProducts: [product.IDProduct],
        idTraining: parseInt(sessionStorage.getItem('idTraining')),
        idType: '353',
      },
    };
    console.log(opportunityDetailInput);
    createOpportunitiesUser({ variables: opportunityDetailInput });
    sessionStorage.setItem('clickOutProduct', product.ClickOut);
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickOutProduct',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const win = window.open(product.ClickOut, '_blank');
    win.focus();
  };

  return (
    <TrainingSectionFlex>
      <Col12
        id="CarruselEmpresasContentHome"
        pt="20px"
        flex="0 0 100%"
        maxwidth="100%"
        display="flex"
      >
        <Carousel
          id="CarruselMetasHome"
          styled={{ background: '#FAFAFA' }}
          transitionMs={1000}
        >
          {products.length !== 0 ? (
            products.map((card) => (
              <Resultado
                wid="90%"
                MinWidth="90%"
                marginGene="auto"
                marri="auto"
                key={card.IDProduct}
                bcolor="#fff"
                ta="left"
                border="none"
                hei="500px"
                minh="453px"
                minhweb="500px"
              >
                <ImageSections
                  width="74px"
                  hmob="47px"
                  ml="5%"
                  heightweb="auto"
                  src={card.ImageURL}
                  className="img-fluid"
                />
                <br />
                <DivStyle
                  className="cabin"
                  mxh="30px"
                  weight="600"
                  align="left"
                  size="12px"
                  line="1.5em"
                  padd="12px 5% 0 5%"
                  color="#07210A"
                >
                  {card.Name}
                </DivStyle>
                <DivGray bcolor="#fff" padd="0 5% 0 5%">
                  <hr />
                  <div>
                    <DivStyle align="left">
                      <DivFlex justify="space-between">
                        {card.benefits.map((detalle) => {
                          if (detalle.Name === 'Anualidad') {
                            return (
                              <CardsTxt
                                color="#4F4F4F"
                                className="roboto"
                                size="14px"
                              >
                                Anualidad{' '}
                                {detalle.Description.includes('USD')
                                  ? detalle.Description
                                  : `$${formatterMx(detalle.Description)}`}
                              </CardsTxt>
                            );
                          }
                        })}
                      </DivFlex>
                      <DivFlex justify="space-between">
                        {card.benefits.map((detalle) => {
                          if (detalle.Name === 'CAT') {
                            return (
                              <CardsTxt
                                color="#4F4F4F"
                                className="roboto"
                                size="14px"
                              >
                                CAT {(detalle.Description * 100).toFixed(2)}%
                              </CardsTxt>
                            );
                          }
                        })}
                      </DivFlex>
                      <DivFlex justify="space-between">
                        {card.benefits.map((detalle) => {
                          if (detalle.Name === 'Interés anual') {
                            return (
                              <CardsTxt
                                color="#4F4F4F"
                                className="roboto"
                                size="14px"
                              >
                                Tasas de interés{' '}
                                {(detalle.Description * 100).toFixed(2)}%
                              </CardsTxt>
                            );
                          }
                        })}
                      </DivFlex>
                      <Images
                        src={stars}
                        top="0"
                        topweb="0"
                        left="0"
                        leftw="0"
                        position="initial"
                        width="116px"
                        widthw="116px"
                        className="img-fluid"
                      ></Images>
                    </DivStyle>
                    <hr />
                    <DivStyle align="left" mh="150px" size="14px">
                      <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                        Beneficios
                      </CardsTxt>
                      {card.benefits.map((detalle) => {
                        if (detalle.Name === 'Beneficio 1') {
                          return (
                            <li className="roboto styleBenef">
                              {detalle.Description}
                            </li>
                          );
                        }
                        if (detalle.Name === 'Beneficio 2') {
                          return (
                            <li className="roboto styleBenef">
                              {detalle.Description}
                            </li>
                          );
                        }
                        if (detalle.Name === 'Beneficio 3') {
                          return (
                            <li className="roboto styleBenef">
                              {detalle.Description}
                            </li>
                          );
                        }
                      })}
                    </DivStyle>
                  </div>

                  <CardsBtn
                    mtop="18px"
                    min_w="100%"
                    onClick={() => generateOpportunities(card)}
                  >
                    Seleccionar
                  </CardsBtn>
                </DivGray>
              </Resultado>
            ))
          ) : (
            <div>
              <DivLoader
                ta="left"
                margin="0 20px 0 0"
                ml="62%"
                mlw="15%"
                wi="110px"
                he="32px"
              >
                <Images
                  src={loader}
                  top="-32px"
                  topweb=""
                  left="2px"
                  leftw="0"
                  width="95px"
                  position="absolute"
                  className="img-fluid rotate180"
                ></Images>
              </DivLoader>
              <DivLoader
                bcolor="transparent"
                ta="left"
                margin="0 20px 0 0"
                ml="62%"
                mlw="15%"
                wi="110px"
                he="32px"
              >
                <TrainingText
                  size="11px"
                  sizew="12px"
                  className="roboto"
                  align="left"
                  alignweb="left"
                  ls="-0.2px"
                  color="#A6A6A6"
                >
                  Escribiendo...
                </TrainingText>
              </DivLoader>
            </div>
          )}
        </Carousel>
      </Col12>
    </TrainingSectionFlex>
  );
};

export default RpProductsWithStars;
