import React, { useState, useEffect, Fragment } from 'react';
import { WhatsappR } from "../common/Home.styled";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
function ChatIcono({url}) {
  
  return (
    <WhatsappR
        id="msjWhats"
        href={
          url !== ""
            ? url
            : `https://api.whatsapp.com/send?phone=000000&text=¡Hola! Quisiera platicar con un experto financiero para obtener más información de Coru.
              )}`
        }
        target="_blank"
      >
        <WhatsAppIcon style={{ fontSize: 40, color: "#fff" }} />
    </WhatsappR>
  );
}

export default ChatIcono;
