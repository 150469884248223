import React from 'react'
import GetCardImage from '../../LpHomeCoru/Assets/img/GetCard.svg'
import ShapeGetCard from '../../LpHomeCoru/Assets/img/ShapeGetCard.svg'

import {
    Wrapper,
    ImageCointainer,
    Title,
    ButtonsContainer,
    ButtonPrimary,
    TitleContainer,
    Image,
    Content,
    DescriptionContainer,
    Description,
    SubContentColor,
  } from './styles.jsx';

const GetCard = () => {
  return (
    <Wrapper>
        <Content>
            <TitleContainer>
                <Title>¡Consigue tu tarjeta de forma sencilla y sin complicaciones!</Title>
            </TitleContainer>
            <DescriptionContainer>
                <Description>
                    Haz realidad tus metas y disfruta de beneficios exclusivos al solicitar una de las mejores tarjetas de crédito con nosotros.
                    <SubContentColor>
                        ¡No esperes más, solicita la tuya ahora y da el primer paso hacia un mundo de ventajas financieras!
                    </SubContentColor>
                </Description>
            </DescriptionContainer>
            <ButtonsContainer>
                <ButtonPrimary
                    onClick={() => {
                        window.location.href = 'https://coru.com/kanda/wo/cc-ft?utm_source=organic&utm_medium=modal&utm_campaign=home2';
                    }}
                >
                    Solicitala aquí
                </ButtonPrimary>
            </ButtonsContainer>
            <ImageCointainer>
                <Image src={GetCardImage} alt='getcard' />
            </ImageCointainer>
        </Content>
    </Wrapper>
  )
}

export default GetCard