/* eslint-disable max-len */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Background,
  Subtitle,
  Container,
  GetStartContainer,
  StartText,
  ButtonSecondary,
  ButtonContainer,
  CardsContainer,
  Card,
  TitleCard,
  TextCard,
  WrapperT
} from "./styles.jsx";
import ClickButton from "../../../Helpers/HookHelpers";

/* assets */
import reduceCAC from "../Assets/Imgs/reduce_CAC.svg";
import aumentaLTV from "../Assets/Imgs/aumenta_LTV.svg";
import NETSCORE from "../Assets/Imgs/NETSCORE.png";
/* styles */
import {
 ImgGeneric, SubContent, TextTitle1
} from "../Assets/Styles/CaasNew.styles.js";

const WhyCoru = ({
  i1, i2, i3, i4, i5, i6, i7, i8, i9, i10, url
}) => {
  const [overCardId, setOverCardId] = useState(0);
  const { actions } = ClickButton();
  const cardList = [
    {
      title: i5, description: i6, id: 1, img: reduceCAC, textInf: '20% menos que el mes anterior', subTextinf: undefined, textLTV: undefined, subTextinfLTV: undefined,
    },
    {
      title: i7, description: i8, id: 2, img: NETSCORE, textInf: undefined, subTextinf: undefined, textLTV: undefined, subTextinfLTV: undefined,
    },
    {
      title: i9, description: i10, id: 3, img: aumentaLTV, textInf: undefined, textLTV: 'Lifetime Value', subTextinfLTV: '$4,380.10 USD',
    },
  ];
  const handleClickCoru = () => {
    actions({
      action: 'ClickButtonLPCaaS',
      variables: `Click_btndemoPorque_no.wo`,
      collection: 'ClickButtonLPCaaS',
    });
    window.open(url, '_blank');
  };

  return (
    <WrapperT>
      <Background>
        <Container>
          <SubContent width="-webkit-fill-available" color='#F64282' msize='20px'>{i1}</SubContent>
          <TextTitle1 weight="900" mobilMargin='28px 0 0 0' margin='28px 0 0 0'>
            {i2}
          </TextTitle1>
          <GetStartContainer>
            <SubContent>
              {i3}
            </SubContent>
            <ButtonContainer>
              <ButtonSecondary onClick={() => handleClickCoru()} >{i4}</ButtonSecondary>
            </ButtonContainer>
          </GetStartContainer>
          <CardsContainer margin="88px auto 20px">
            {cardList.map((cardItem) => (
              <Card
                key={cardItem.title}
                isOver={overCardId === cardItem.id}
                /* onMouseOver={() => setOverCardId(cardItem.id)} */
              >
                <TitleCard>{cardItem.title}</TitleCard>
                <TextCard fontSize="12px" justifyContent="center">{cardItem.description}</TextCard>
                <ImgGeneric src={cardItem.img} />
              </Card>
            ))}
          </CardsContainer>
          {/* <TitleSecondary>{idc('GoalOriented')}</TitleSecondary>
          <Agiletext>{idc('GoalOriented_Title')}</Agiletext>
          <EnsuringText>
            {idc('GoalOriented_Subtitle')}
          </EnsuringText>
          <CaruselContainer>
            <CarouselProfesion idc={idc} />
          </CaruselContainer> */}
        </Container>
      </Background>
    </WrapperT>
  );
};

export default WhyCoru;
