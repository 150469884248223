/* eslint-disable import/extensions */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import DateInput from "./DateInput";
import "react-phone-input-2/lib/style.css";
import { Box, Typography } from "@mui/material";
import { useMutation, useLazyQuery } from "@apollo/client";
import DemoImg from "../Assets/Imgs/Demo.png";
import {
  TextTitle1,
  ContentSpan,
  StyledDemoContainer,
  DemoTitleContainer,
  DemoFormContainer,
  Button2,
  SubContent,
  StartedButton,
  ContainerGeneral,
  GenContainer,
} from "../Assets/Styles/CaasNew.styles";

import Navbar from "../Navbar";
import LanguageDemo from "../LanguageMenu/LanguageDemo";
import { CREATE_SESSION, SCHEDULE_APPOINTMENT_DEMO } from "../../../mutations";
import { GET_APPOINTMENTS_DEMO, GET_TRANSLATE_INFO } from "../../../queries";
import { useCreateClickBtnMongo } from "../../../hooks/useCreateClickBtnMongo";
import DateTimePickerValue from "./dateTime.jsx";
import FormInput from "./FormInput";
import CalendarTime from "./CalendarTime";
// eslint-disable-next-line import/no-unresolved
import Footer from "../Footer/index";
// eslint-disable-next-line import/order
import moment from "moment";

const Demo = () => {
  const { createClickBttn } = useCreateClickBtnMongo();
  const [view, setView] = useState("form");
  const [languageMenu, setLanguageMenu] = useState(false);
  const [selectLang, setSelectLang] = useState(1);
  const [selectDateTime, setSelectDateTime] = useState();
  const [selectedTime, setSelectedTime] = useState("00:00");
  const [flatDateTime, setFlatdateTime] = useState("");
  const [textErrorForm, setTextErrorForm] = useState("");
  const [infoTranslate, setInfoTranslate] = useState();

  const [dtaTimer, setDtaTimer] = useState(false);
  const [openDateModal, setOpenDateModal] = useState(false);
  const [appointments, setAppointments] = useState([
    "2023-07-20 13:15",
    "2023-07-20 13:30",
  ]);
  const urlh = window.location.href;
  let data = {};
  const [dataForm, setDataForm] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    nameEnterprise: "",
    dateTime: "",
  });

  const [getAppoinmentsDemo] = useLazyQuery(GET_APPOINTMENTS_DEMO, {
    onCompleted({ getAppoinmentsDemo }) {
      if (getAppoinmentsDemo.statusCode === 200) {
        setAppointments(JSON.parse(getAppoinmentsDemo.response));
      }
    },
  });

  const [getInfoTranslate] = useLazyQuery(GET_TRANSLATE_INFO, {
    onCompleted({ getTranslateInfo }) {
      const { response } = getTranslateInfo;
      setInfoTranslate(JSON.parse(response));
    },
  });

  const [createCollection] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession) {
      }
    },
    onError(error) {
      console.log(
        "🚀 ~ file: useCreateCollectionButtons.js:12 ~ onError ~ error:",
        error
      );
    },
  });

  const [scheduleAppointmentDemo] = useMutation(SCHEDULE_APPOINTMENT_DEMO, {
    onCompleted({ scheduleAppointmentDemo }) {
      if (scheduleAppointmentDemo.statusCode === 400) {
        setTextErrorForm(scheduleAppointmentDemo.message);
        return;
      }
      if (
        scheduleAppointmentDemo &&
        scheduleAppointmentDemo.statusCode === 200
      ) {
        data = {
          idSession:
            sessionStorage.getItem("idDatSession") ||
            sessionStorage.getItem("id_session") ||
            "0",
          dataForm,
          env:
            process.env.REACT_APP_SERVERTYPE === "Production" ? "Prod" : "Dev",
        };
        setView("message");
        createClickBttn({
          action: "Click_Agendademo_enviar",
        });
        createCollection({
          variables: {
            input: {
              collection: "LPCaaSDemo",
              data: JSON.stringify(data),
            },
          },
        });
      }
    },
    onError(error) {
      console.log(
        "🚀 ~ file: useCreateCollectionButtons.js:12 ~ onError ~ error:",
        error
      );
    },
  });

  const getTime = (e) => {
    setDtaTimer(e.hasOwnProperty("$d"));
    setSelectDateTime(e.$d);
  };

  useEffect(() => {
    setTextErrorForm("");
    const formatDate = moment(selectDateTime).format("DD-MM-YYYY");
    const formatDateTime = `${formatDate} ${selectedTime}`;
    setFlatdateTime(formatDateTime);
    if (dtaTimer && selectDateTime) {
      setDataForm({
        ...dataForm,
        dateTime: formatDateTime,
      });
    }
  }, [selectDateTime, selectedTime]);

  const handleChange = (e) => {
    setTextErrorForm("");
    const { name, value } = e.target;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    scheduleAppointmentDemo({
      variables: {
        input: {
          fullName: dataForm.fullName,
          email: dataForm.email,
          companyName: dataForm.nameEnterprise,
          appointmentDate: dataForm.dateTime,
        },
      },
    });
  };

  useEffect(() => {
    getAppoinmentsDemo({
      variables: {},
    });
  }, []);

  useEffect(() => {
    getInfoTranslate({
      variables: {
        language: selectLang,
      },
    });
  }, [selectLang]);

  const setInfo = (section) => {
    if (infoTranslate && infoTranslate.length > 0) {
      const demo = infoTranslate.find((f) => f.Section === section);
      if (demo) return demo.Description;
      else return null;
    }
    return null;
  };

  const Message = () => (
    <DemoFormContainer>
      <h4>¡Gracias por tu interés!</h4>
      <SubContent size="14px">
        Estamos encantados de poder ayudarte a alcanzar tus objetivos. Hemos
        recibido tu solicitud y nuestro equipo de expertos se pondrá en contacto
        contigo a la brevedad para brindarte la atención que mereces.
        <br />
        <br />
        Te invitamos a seguirnos en nuestras redes sociales para que estés al
        tanto de las últimas noticias sobre nuestras soluciones.
        <br />
        <br />
        Gracias nuevamente por confiar en nosotros.
        <br />
        <br />
        Con cariño, El equipo de Coru as a Service.
      </SubContent>
      <StartedButton>Regresar a Coru</StartedButton>
    </DemoFormContainer>
  );

  return (
    <GenContainer>
      <Navbar languageMenu={languageMenu} setLanguageMenu={setLanguageMenu} />
      <ContainerGeneral>
        <StyledDemoContainer>
          <DemoTitleContainer>
            <TextTitle1 msize="30px">
              {setInfo("Demo_Title1")}
              {/* Descubre el poder de la */}
              <br />
              <ContentSpan>
                {setInfo("Demo_Title2")}
                {/* plataforma Coru */}
              </ContentSpan>
            </TextTitle1>
            <SubContent width="580px" margin="10px 0 40px 0" size="16px">
              {setInfo("Demo_Texto1")}
              {/* Agenda una conversación con nuestros expertos y experimenta una
              demo de nuestras soluciones con el poder de IA, para mejorar la
              satisfacción de tus clientes. */}
            </SubContent>
            <img src={DemoImg} alt="demoImg" />
          </DemoTitleContainer>
          {view === "form" && (
            <Box
              display="flex"
              flexDirection="column"
              width={{ xs: "100%", md: "40%" }}
              gap="32px"
            >
              <Typography
                variant="h4"
                color="#F64282"
                fontSize="24px"
                fontFamily="cabin"
                fontWeight="700"
                marginTop={{ xs: "70px", sm: "10px" }}
              >
                {setInfo("Demo_Form_Title")}
                {/* Solicitar una demostración */}
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" gap="16px">
                  <FormInput
                    id="fullName"
                    name="fullName"
                    value={dataForm.fullName}
                    autoFocus
                    required
                    placeholder={setInfo("Demo_Form_Box_1")} //"Nombre completo"
                    onChange={handleChange}
                  />
                  <PhoneInput
                    country={"mx"}
                    containerStyle={{
                      width: "100%",
                      padding: "0px",
                      border: "none",
                    }}
                    inputProps={{
                      name: "phoneNumber",
                      onChange: handleChange,
                    }}
                    value={dataForm.phoneNumber}
                    inputStyle={{
                      width: "100%",
                      backgroundColor: "#fff",
                      height: "44px",
                      fontSize: "16px",
                      border: "none",
                      borderBottom: "1px solid #949494",
                      borderBottomRightRadius: "0",
                      borderTopRightRadius: "8px",
                    }}
                    onChange={(value, data, event, formattedValue) => {
                      handleChange(event);
                    }}
                  />
                  <FormInput
                    id="email"
                    name="email"
                    value={dataForm.email}
                    autoFocus
                    required
                    placeholder={setInfo("Demo_Form_Box_3")} //"Correo electrónico"
                    onChange={handleChange}
                  />
                  <FormInput
                    id="nameEnterprise"
                    name="nameEnterprise"
                    value={dataForm.nameEnterprise}
                    autoFocus
                    required
                    placeholder={setInfo("Demo_Form_Box_4")} //"Nombre de la empresa"
                    onChange={handleChange}
                  />
                </Box>
                <Box
                  marginTop="32px"
                  gap="20px"
                  display="flex"
                  flexDirection="column"
                >
                  <Typography
                    color="#464646"
                    fontSize="16px"
                    letterSpacing="1px"
                  >
                    {setInfo("Demo_Form_SubTitle")}
                    {/* Agenda tu Demo */}
                  </Typography>
                  {/* <DateTimePickerValue
                    onSelectDateTime={getTime}
                    appointments={appointments}
                  /> */}
                  <CalendarTime
                    openModal={openDateModal}
                    appointments={appointments}
                    selectedTime={selectedTime}
                    onSetOpenModal={setOpenDateModal}
                    onSelectDateTime={getTime}
                    onSetSelectedTime={setSelectedTime}
                  />
                  <DateInput
                    hasSelectedDate={!!selectDateTime}
                    textInput={moment(flatDateTime, "DD-MM-YYYY HH:mm").format(
                      "DD-MM-YYYY HH:mm A"
                    )}
                    onClick={() => setOpenDateModal(true)}
                  />
                </Box>
                <Box marginTop={textErrorForm ? "30px" : "60px"}>
                  <Typography fontSize="14px" color="red">
                    {textErrorForm}{" "}
                  </Typography>
                  <Button2 margin="10px 0 0 0" width="100%">
                  {setInfo("Demo_Form_Btn")}
                    {/* Enviar */}
                  </Button2>
                </Box>
              </form>
            </Box>
          )}
          {view === "message" && (
            <>
              <Message />
            </>
          )}
        </StyledDemoContainer>
      </ContainerGeneral>
      {languageMenu && (
        <LanguageDemo setSelectLang={setSelectLang} selectLang={selectLang} />
      )}
      <Footer
        i1={"Coru"}
        i2={"Sobre nosotros"}
        i3={"Contáctanos"}
        i4={"Soporte"}
        i5={"Contenido relacionado"}
        i6={"¿Cómo funciona un diagnóstico de usuario?"}
        i7={"¿Qué beneficios tiene la gamificación?"}
        i8={"¿Qué es el onboarding digital?"}
        i9={""}
        i10={"FAQ"}
        i11={"¿Cómo contrato a Coru?"}
        i12={"Casos de éxito"}
        i13={"Aviso de privacidad Coru 2024"}
        selectLang={selectLang}
      />
    </GenContainer>
  );
};

export default Demo;
