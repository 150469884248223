import React, { Fragment, useEffect, useState } from "react";
import {
  Switch, Route, Redirect, useLocation
} from "react-router-dom";
import { useQuery } from "@apollo/client";
import LoginAdmin from "../components/common/LoginAdmin";
import AppAdmin from "../components/common/AdminHome/App/index";
import ChatIcono from "../components/Home/ChatIcono";
import NotFound from "../anaCoach/404";
import { GET_CAT_ROUTES_Q } from "../queries";
import Extra from "./Exportcomponents/ExtraRoutesComponents";
// import LpCoppel from '../anaCoach/Training/LpCoppel';

const ExtraRoutes = () => {
  // ! se deben anotar las rutas en el siguiente array para que no aparezca como 404, ademas
  /* ! si el componente 404 se acopla al componente puede ser que su router no lleve el
  parametro exact */

  const pathNames = [
    "/",
    "/lp/home",
    "/the-training-feed/",
    "/the-training-feed-omar/",
    "/user-validation/",
    "/user-validation-otp/",
    "/personal-Information",
    "/personal-Information-v2",
    "/password-New",
    "/login",
    "/login-v2",
    "/forgot-Password",
    "/reset-Password",
    "/ambassadors-registration",
    "/embajadores_registro",
    "/wo/first-card",
    "/wo/rejection-reasons",
    "/wo/no-credit-history",
    "/wo/compare-credit-card",
    "/wo/card-compare/success",
    "/wo/card-funnel",
    "/wo/card-funnel-two",
    "/wo/card-funnel-test",
    "/wo/card-funnel-OCA",
    "/wo/mvp",
    "/coach/thetrainig/successFastTrack",
    "/wo/family-protection",
    "/wo/family-protection/success",
    "/wo/new-years-resolutions-success",
    "/wo/reasons-success",
    "/wo/profiling",
    "/wo/car-insurance",
    "/wo/profiling/success",
    "/wo/emergency-saving",
    "/wo/investment-test",
    "/wo/virality-test",
    "/wo/investment-simulator",
    "/wo/new-years-resolutions",
    "/wo/loans-funnel",
    "/wo/debts",
    "/wo/first-investment",
    "/wo/first-investment-V2",
    "/wo/investment-catalog",
    "/wo/profiling-2",
    "/wo/profiling-2/success",
    "/wo/extra-income",
    "/wo/fast-track",
    "/wo/budget",
    "/wo/card-funnel/success",
    "/wo/first-card/success",
    "/wo/personal-loans/success",
    "/wo/budget-improve",
    "/wo/goals",
    "/wo/collaborative",
    "/wo/collaborative-success",
    "/wo/pymes",
    "/wo/pymes-success",
    "/lp/page-planes",
    "/coach/trainings/lp-first-investment",
    "/coach/thetrainig/LPXCoach",
    "/lp/page-planes",
    "/profile",
    "/profile/user-info",
    "/profile/address",
    "/profile/payment-method",
    "/achievements/activity",
    "/achievements",
    "/achievements/completed",
    "/achievements/inprogress",
    "/example",
    "/admin-home/black-list",
    "/the-gym/for-you",
    "/autocomplete-address",
    "/phone-validate",
    "/p/analytics/:medium",
    "/shorter",
    "/wo/twin-funnel-cc/step1-initial-data",
    "/coach/training/adquieretutarjetadecredito/twinfunnel/step2-comparativo",
    "/coach/training/adquieretutarjetadecredito/twinfunnel/step3-infopersonal",
    "/coach/training/adquieretutarjetadecredito/twinfunnel/step4-domicilio",
    "/coach/training/adquieretutarjetadecredito/twinfunnel/step5-datos-autenticación",
    "/coach/training/adquieretutarjetadecredito/twinfunnel/step5-laboral",
    "/coach/training/adquieretutarjetadecredito/twinfunnel/login-cc",
    "/coach/training/adquieretutarjetadecredito/twinfunnel/forgot-password-cc",
    "/coach/training/adquieretutarjetadecredito/twinfunnel/reset-password-cc",
    "/coach/training/adquieretutarjetadecredito/twinfunnel/user-validation-cc",
    "/coach/training/adquieretutarjetadecredito/twinfunnel/validation-otp-cc",
    "/coach/training/adquieretutarjetadecredito/twinfunnel/personal-information-cc",
    "/coach/training/adquieretutarjetadecredito/twinfunnel/password-new-cc",
    "/hook",
    "/answer",
    "/f1",
    "/f2",
    "/f3",
    "/f4",
    "/f5",
    "/c",
    "/admin",
    "/admin/",
    "/test-aws-personalize",
    "/daily-task-log",
    "/weekly-summary",
    "/privacy-notice",
    "/about-us",
    "/acitivity-summary",
    "/mvp-money",
    "/quiz_money",
    "/mvp-investment",
    "/quiz_investment",
    "/test-aws-personalize",
    "/advisers-home",
    "/advisers",
    "/asesores-prueba",
    "/admin-home/trainings",
    "/admin-home/adminTrainings",
    "/admin-home/controlclickouts/",
    "/admin-home/transactional",
    "/admin-home/adminTrainings/",
    "/admin-home/pixel/",
    "/admin-home/shortlink/",
    "/admin-home/config-otp/",
    "/admin-home/imageUpload/",
    "/admin-home/adminScrappers/",
    "/admin-home/createProduct/",
    "/admin-home/template/",
    "/admin-home/campaign/",
    "/admin-home/dashboardSnc/",
    "/admin-home/blacklist/",
    "/admin-home/overview/",
    "/admin-home/overview/campaing",
    "/admin-home/opportunititesInfo/",
    "/admin-home/viewUserInfoDetail/",
    "/admin-home/",
    "/admin-home",
    "/admin-home/EditTraining",
    "/admin-home/editTraining/",
    "/admin-home/createTraining",
    "/admin-home/createTraining/",
    "/admin-home/validate-emails/",
    "/admin-home/score-whatsapp/",
    "/admin-home/control-productos/",
    "/admin-home/Dripify/",

    "/admin-home#!",
    "/admin-home/#!",
    "/admin-home/EditTraining#!",
    "/admin-home/editTraining/#!",
    "/admin-home/createTraining#!",
    "/admin-home/createTraining/#!",
    "/admin-home/trainings#!",
    "/admin-home/adminTrainings#!",
    "/admin-home/pixel/#!",
    "/admin-home/shortlink/#!",
    "/admin-home/config-otp/#!",
    "/admin-home/imageUpload/#!",
    "/admin-home/adminScrappers/#!",
    "/admin-home/createProduct/#!",
    "/admin-home/adminTrainings#!",
    "/admin-home/transactional#!",
    "/admin-home/template/#!",
    "/admin-home/campaign/#!",
    "/admin-home/dashboardSnc/#!",
    "/admin-home/blacklist/#!",
    "/admin-home/overview/#!",
    "/admin-home/opportunititesInfo/#!",
    "/admin-home/viewUserInfoDetail/#!",
    "/admin-home/Dripify/#!",

    "/admin-home/mkt-automation/",
    "/admin-home/CaaS-generico/",
    "/admin-home/Rewards/",
    "/admin-home/trainings/#!",
    "/admin-home/adminTrainings/#!",
    "/admin-home/transactional/#!",
    "/admin-home/adminTrainings#!",
    "/admin-home/pixel#!",
    "/admin-home/shortlink#!",
    "/admin-home/config-otp#!",
    "/admin-home/imageUpload#!",
    "/admin-home/adminScrappers#!",
    "/admin-home/createProduct#!",
    "/admin-home/template#!",
    "/admin-home/campaign#!",
    "/admin-home/dashboardSnc#!",
    "/admin-home/blacklist#!",
    "/admin-home/overview#!",
    "/admin-home/opportunititesInfo#!",
    "/admin-home/viewUserInfoDetail#!",
    "/reset-password",
    "/forgot-password",
    "/password-new",
    "/wo/micro-expenses",
    "/demo2021",
    "/demo2021_v2",
    "/lp/get-card",
    "/lp/saving-dollars",
    "/lp/saving-dollars/Success",
    "/lp/saving-dollars",
    "/wo/card-funnel-AB",
    "/score-question-video",
    "/wo/afores",
    "/lp/stori",
    "/lp/super-seguros",
    "/wo/know-your-score",
    "/lp/rappi",
    "/contact-rappi",
    "/verification-code-rappi",
    "/credit-history-rappi",
    "/verification-error-rappi",
    "/success-rappi",
    "/wo/ci-test",
    "/lp/nu",
    "/contact-nu",
    "/verification-code-nu",
    "/success-nu",
    "/success-ci",
    "/lp/vexi",
    "/contact-vexi",
    "/verification-code-vexi",
    "/success-vexi",
    "/lp/categories-products",
    "/lp/coppel",
    "/lp/coppelOTP",
    "/lp/coppelAdress",
    "/lp/coppelSuccess",
    "/lp/waitlistKanda",
    "/wo/money-value",
    "/wo/glove-box",
    "/wo/card-funnel-r",
    "/MagicLink",
    "/for-business",
    "/lp/brasil",
    "/app",
    "/lp/quoter-ci",
    "/lp/tu-plan",
    "/lp/cmc",
    "/lp/xcoach",
    "/caas/es/",
    "/caas/en/",
    "/caas/pt/",
    "/caas/",
    "/caas",
    "/caas/agendademo",
    "/expertofinanciero",
    "/asegurador",
  ];

  const location = useLocation();
  const { pathname } = location;
  const [bandera, setBandera] = useState(false);
  const { data: result } = useQuery(GET_CAT_ROUTES_Q, {
    fetchPolicy: "no-cache",
    variables: { routeType: "Extra" },
  });
  // eslint-disable-next-line
  const Element = ({ component }) => {
    const Component = Extra[component];
    return <Component />;
  };
  useEffect(() => {
    let urlRedirect = "";
    let flagRedirect = true;
    // debugger;
    if (!pathNames.includes(pathname)) {
      switch (pathname) {
        case "/coach/thetrainig/credito/tarjeta-de-credito/obtener-primer-tarjeta":
          urlRedirect = "/wo/first-card";
          break;
        case "/coach/thetrainig/credito/tarjeta-de-credito/motivos-de-rechazo":
          urlRedirect = "/wo/rejection-reasons";
          break;
        case "/coach/thetrainig/credito/tarjeta-de-credito/comparar-tarjeta":
          urlRedirect = "/wo/compare-credit-card";
          break;
        case "/coach/thetrainig/credito/tarjeta-de-credito/funnel-tarjeta":
          urlRedirect = "/wo/card-funnel";
          break;
        case "/coach/thetrainig/credito/tarjeta-de-credito/funnel-tarjeta-test":
          urlRedirect = "/wo/card-funnel-test";
          break;
        case "/coach/thetrainig/protection/family/family-protection":
          urlRedirect = "/wo/family-protection";
          break;
        case "/coach/thetrainig/emergency-saving":
          urlRedirect = "/wo/emergency-saving";
          break;
        case "/coach/thetrainig/investment-test":
          urlRedirect = "/wo/investment-test";
          break;
        case "/coach/thetrainig/investment-simulator":
          urlRedirect = "/wo/investment-simulator";
          break;
        case "/coach/thetrainig/credito/prestamos-personales/funnel-prestamos":
          urlRedirect = "/wo/loans-funnel";
          break;
        case "/coach/thetrainig/credito/debts":
          urlRedirect = "/wo/debts";
          break;
        case "/coach/thetrainig/FirstInversion":
          urlRedirect = "/wo/first-investment";
          break;
        case "/coach/thetrainig/InvestmentCatalog":
          urlRedirect = "/wo/investment-catalog";
          break;
        case "/coach/thetrainig/ExtraIncome":
          urlRedirect = "/wo/extra-income";
          break;
        case "/coach/thetrainig/FastTrack":
          urlRedirect = "/wo/fast-track";
          break;
        case "/coach/thetrainig/TrainingBudget":
          urlRedirect = "/wo/budget";
          break;
        case "/coach/thetrainig/ImproveBudget":
          urlRedirect = "/wo/budget-improve";
          break;
        case "/coach/thetrainig/WOGoals":
          urlRedirect = "/wo/goals";
          break;
        case "/coach/thetrainig/WOValueOfMoney":
          urlRedirect = "/wo/money-value";
          break;
        case "/coach/Training/LpObtenerTarjeta":
          urlRedirect = "/coach/Training/LpObtenerTarjeta";
          break;
        case "/coach/Training/GloveBox":
          urlRedirect = "/wo/glove-box";
          break;
        default:
          flagRedirect = false;
          break;
      }
      if (flagRedirect === true) {
        window.location.href = urlRedirect;
      } else {
        setBandera(true);
      }
    } else {
      setBandera(false);
    }
  }, []);
  return (
    <>
      <Switch>
        {result?.getCatRoutes.map((route) => (
          <Route exact path={route.path} component={() => Element(route)} key={route.id} />
        ))}
        {(bandera || pathname === "/404") && (
          <>
            <Route path="/404" render={() => <NotFound setBandera={setBandera} />} />
            <Redirect from="*" to="/404" />
          </>
        )}

        <AppAdmin />
        <Route exact path="/test-aws-personalize" component={LoginAdmin} />
      </Switch>
      <ChatIcono />
    </>
  );
};

export default ExtraRoutes;
