/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import Lottie from "react-lottie";
import BackgroundPink from "../Assets/Imgs/shapePinkBackground.svg";
import CoruAnim1 from "../Assets/Lotties/Coru-gifheroCorto-spa.json";
import CoruAnim1Eng from "../Assets/Lotties/Coru-gifheroCorto-eng.json";
import CoruAnim1Por from "../Assets/Lotties/Coru-gifheroCorto-port.json";

import {
  ContainerPage1,
  ContainerGeneral,
  Content1,
  TextTitle1,
  ContentSpan,
  SubContent,
  Button2,
  Video,
  ShapeBackground
} from "../Assets/Styles/CaasNew.styles";
import ClickButton from "../../../Helpers/HookHelpers";

const EmpowerBusiness = ({
  i1, i2, i3, i4, i5, i6, language, url
}) => {
  const { actions } = ClickButton();
  useEffect(() => {
    const videostatus = document.getElementById("video1");
  }, []);

  const handleClickCoru = (name) => {
    switch (name) {
      case "solutions":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_solutions_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "products":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_products_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "coru":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_coru_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "successstories":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_casosexito_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "traductor":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_traductor_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "getstarted":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_demo_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonReadytoStartHero":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonDemo_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        // window.location.href = "/caas/agendademo";
        window.open(url, '_blank');
        break;
      case "BottonLetsGetToWork":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_btndemoProductos_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonStartnow":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonStartnow`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonSeeallProducts":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonSeeallProducts`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      default:
        break;
    }
  };

  return (
    <ContainerPage1>
      <ContainerGeneral
        direction="row"
        tdirection="column"
        tjustify="space-between"
        justify="space-between"
        height="fit-content"
        gap="50px"
        // mgap="118px"
      >
        <Content1>
        <TextTitle1 size="52px" width='572px' weight="900">
                  {i1}{" "}
                  <ContentSpan size="52px" transform="lowercase" weight="900">{i2}</ContentSpan>{" "}
                  <ContentSpan size="52px" weight="900">{i3}</ContentSpan>
                  {/* <TextTitle1 size="52px" weight="900">{i6}</TextTitle1> */}
                </TextTitle1>
                <SubContent margin="12px 0 0" width='572px' >
                  {i4}
                </SubContent>
                <Button2
                  margin="32px 0 0"
                  onClick={() => handleClickCoru("BottonReadytoStartHero")}
                >
                  {i5}
                </Button2>
        </Content1>
        <Video>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: language === 1 ? CoruAnim1 : language === 2 ? CoruAnim1Eng : CoruAnim1Por,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                />
              </Video>
      </ContainerGeneral>
      <ShapeBackground
       alt=""
       src={BackgroundPink}
       style={{ bottom: "0" }}
      />
    </ContainerPage1>
  );
};

export default EmpowerBusiness;
