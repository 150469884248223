import React, { useRef, useState } from 'react'
import { Box } from "@mui/material";
import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  border: 0;
  ::value {
    color: #212529;
  }
  ::placeholder {
    color: #959595;
  }
`

const FormInput = ({
  id,
  name,
  value,
  autofocus,
  required,
  placeholder,
  onChange,
}) => {
  const inputRef = useRef(null)
  const [isHover, setIsHover] = useState(false)

  return (
    <Box
      height="44px"
      bgcolor="white"
      padding="12px 16px"
      display="flex"
      justifyContent="space-between"
      sx={{
        borderBottom: isHover ? '1px solid #212529' : "1px solid #959595",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        cursor: 'text',
      }}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      onClick={() => inputRef.current.focus()}
    >
      <Input
        ref={inputRef}
        type='text'
        id={id}
        name={name}
        value={value}
        autoFocus={autofocus}
        required={required}
        placeholder={placeholder}
        onChange={onChange}
      />
    </Box>
  )
}

export default FormInput