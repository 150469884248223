import React from 'react';
import {
  LoaderDivComplete,
  ImageLoad,
} from './common.styled';
import ImageLoader from '../../assets/img/anacoach/loader_coru.svg';

function GeneralLoader() {
  return (
    <LoaderDivComplete>
      <ImageLoad
        cursor=""
        src={ImageLoader}
        widthweb="20%"
        width="40%"
        mwidth="40%"
        heightMobile="auto"
        alt="loader-coru"
        position="absolute"
      />
    </LoaderDivComplete>
  );
}

export default GeneralLoader;
