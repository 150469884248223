/* eslint-disable react/prop-types */
import React from 'react';
import {
  Title, CarruselContainer, Wrapper, Carrusel
} from './styles.jsx';

const Partners = ({ info }) => (
    <Wrapper>
        <Title>{info}</Title>
        <CarruselContainer>
            <Carrusel />
        </CarruselContainer>
    </Wrapper>
);

export default Partners;
