/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import query components and helpers
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_PRODUCTS_BY_IDTRAINING_AND_NAME,
  RECOVERY_ROUTINE_BY_ORDER,
} from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import loader from '../../assets/img/anacoach/loading.svg';
import CardOne from '../../components/Home/Card1Animate';
import {
  DivLoader,
  Images,
  TrainingText,
} from '../../assets/styles/Training.styled';

const InmuebleSinUsar = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  data,
  setDescIntro,
  extraIncomeProducts,
  setExtraIncomeProducts,
}) => {
  const productNames = [
    'Airbnb',
    'Nidorooms',
    'Dadaroom',
    'Roomgo',
    'Roomster',
    'Homie',
  ];

  const [productsData, setProductsData] = useState([]);

  const {
    loading,
    error,
    data: prodData,
  } = useQuery(GET_PRODUCTS_BY_IDTRAINING_AND_NAME, {
    variables: {
      idTraining: parseInt(currentTraining),
      names: productNames,
    },
    onCompleted({ getProductsByIdTrainingAndName }) {
      const products = JSON.parse(getProductsByIdTrainingAndName.response);
      setProductsData(products);
    },
    onError(error) {
      console.log('error', error);
    },
  });

  if (loading) {
    return (
      <div>
        <DivLoader
          ta="left"
          margin="0 20px 0 0"
          ml="25%"
          mlw="15%"
          wi="110px"
          he="32px"
        >
          <Images
            src={loader}
            top="-32px"
            topweb=""
            left="2px"
            leftw="0"
            width="95px"
            position="absolute"
            className="img-fluid rotate180"
          ></Images>
          {/* <CircularProgress color="inherit" /> */}
        </DivLoader>
        <DivLoader
          bcolor="transparent"
          ta="left"
          margin="0 20px 0 0"
          ml="25%"
          mlw="15%"
          wi="110px"
          he="32px"
        >
          <TrainingText
            size="11px"
            sizew="12px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.2px"
            color="#A6A6A6"
          >
            Escribiendo...
          </TrainingText>
        </DivLoader>
      </div>
    );
  }
  if (error) return `Error: ${error}`;

  return (
    <InmuebleSinUsarComponent
      Date={Date}
      hora={hora}
      orderRutine={orderRutine}
      setOrderRutine={setOrderRutine}
      currentTraining={currentTraining}
      createJsonInput={createJsonInput}
      questions={questions}
      setQuestions={setQuestions}
      currentRoutine={currentRoutine}
      nextOrderRoutine={nextOrderRoutine}
      trainingColor={trainingColor}
      data={productsData}
      setDescIntro={setDescIntro}
      extraIncomeProducts={extraIncomeProducts}
      setExtraIncomeProducts={setExtraIncomeProducts}
    />
  );
};

const InmuebleSinUsarComponent = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  data,
  setDescIntro,
  extraIncomeProducts,
  setExtraIncomeProducts,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const handleChangeCatOption = () => {
    const products = data.filter(
      (e) => e.Name.startsWith('Airbnb')
        || e.Name.startsWith('Nidorooms')
        || e.Name.startsWith('Dadaroom')
        || e.Name.startsWith('Roomgo')
        || e.Name.startsWith('Roomster')
        || e.Name.startsWith('Homie'),
    );
    setDescIntro(
      'Si en tu casa tienes una habitación que no ocupas, o alguno de tus familiares tiene una casa sin habitar, aprovecha esta oportunidad y obtén un ingreso extra rentando el espacio mediante diferentes páginas como:',
    );
    // setNewScrollPos(sizeHeight);
    setExtraIncomeProducts(products);
    gsap.to(window, { duration: 1, scrollTo: '#activeInmuebles' });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          // setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          const answers = {
            1: '',
          };
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionOrder } = arrayResponseRutine[i];
              const { questionType } = arrayResponseRutine[i];
              if (responseUser && responseUser !== '') {
                answers[questionOrder] = responseUser;
              }

              if (questionType === 'Cat') {
                const { detailQuestion } = routines[idRoutine].questions[i];
                for (let x = 0; x < detailQuestion.length; x++) {
                  if (detailQuestion[x].FieldOption === responseUser) {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              } else {
                contResponse++;
                sessionStorage.setItem(
                  arraySessionsRutines[idTypeQuestion],
                  responseUser,
                );
              }
            }

            const products = data.filter(
              (e) => e.Name.startsWith('Airbnb')
                || e.Name.startsWith('Nidorooms')
                || e.Name.startsWith('Dadaroom')
                || e.Name.startsWith('Roomgo')
                || e.Name.startsWith('Roomster')
                || e.Name.startsWith('Homie'),
            );
            setDescIntro(
              'Si en tu casa tienes una habitación que no ocupas, o alguno de tus familiares tiene una casa sin habitar, aprovecha esta oportunidad y obtén un ingreso extra rentando el espacio mediante diferentes páginas como:',
            );
            setExtraIncomeProducts(products);

            if (
              arrayResponseRutine.length <= routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              setQuestions([]);
              break;
            } else {
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    handleChangeCatOption('val');
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    }
  }, []);

  return (
    <Fragment>
      <div id="activeInmuebles"></div>
      <CardOne
        classCard={'cardOne'}
        classTop="topOne1"
        text="Continuemos"
        hora={hora}
      />
    </Fragment>
  );
};

export default InmuebleSinUsar;
