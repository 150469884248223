/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  DivLoader,
  Images,
  TrainingQuestion,
  TrainingText,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import {
  GET_PRODUCTS_BY_IDTRAINING_AND_NAME,
  RECOVERY_ROUTINE_BY_ORDER,
} from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import loader from '../../assets/img/anacoach/loading.svg';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';

const NavegarEnInternet = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  data,
  setDescIntro,
  extraIncomeProducts,
  setExtraIncomeProducts,
}) => {
  const productNames = [
    'User Testing',
    'Uxline',
    'Gift hunter club',
    'Cash App',
    'Play Google',
    'Consupermiso',
    'SocialPubli',
    'Publisuites',
    'Caliente MX',
    'Bet 365',
    'Ganabet',
    'Codere MX',
    'Betway MX',
    'Winner.com',
  ];

  const [productsData, setProductsData] = useState([]);

  const {
    loading,
    error,
    data: prodData,
  } = useQuery(GET_PRODUCTS_BY_IDTRAINING_AND_NAME, {
    variables: {
      idTraining: parseInt(currentTraining),
      names: productNames,
    },
    onCompleted({ getProductsByIdTrainingAndName }) {
      const products = JSON.parse(getProductsByIdTrainingAndName.response);
      setProductsData(products);
    },
    onError(error) {
      console.log('error', error);
    },
  });

  if (loading) {
    return (
      <div>
        <DivLoader
          ta="left"
          margin="0 20px 0 0"
          ml="25%"
          mlw="15%"
          wi="110px"
          he="32px"
        >
          <Images
            src={loader}
            top="-32px"
            topweb=""
            left="2px"
            leftw="0"
            width="95px"
            position="absolute"
            className="img-fluid rotate180"
          ></Images>
        </DivLoader>
        <DivLoader
          bcolor="transparent"
          ta="left"
          margin="0 20px 0 0"
          ml="25%"
          mlw="15%"
          wi="110px"
          he="32px"
        >
          <TrainingText
            size="11px"
            sizew="12px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.2px"
            color="#A6A6A6"
          >
            Escribiendo...
          </TrainingText>
        </DivLoader>
      </div>
    );
  }
  if (error) return `Error: ${error}`;

  return (
    <NavegarEnInternetComponent
      Date={Date}
      hora={hora}
      orderRutine={orderRutine}
      setOrderRutine={setOrderRutine}
      currentTraining={currentTraining}
      createJsonInput={createJsonInput}
      questions={questions}
      setQuestions={setQuestions}
      currentRoutine={currentRoutine}
      nextOrderRoutine={nextOrderRoutine}
      trainingColor={trainingColor}
      data={productsData}
      setDescIntro={setDescIntro}
      extraIncomeProducts={extraIncomeProducts}
      setExtraIncomeProducts={setExtraIncomeProducts}
    />
  );
};

const NavegarEnInternetComponent = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  data,
  setDescIntro,
  extraIncomeProducts,
  setExtraIncomeProducts,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [form, setForm] = useState({});
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [primeraPreguntaNavEnInternet, setPrimeraPreguntaNavEnInternet] = useState(sessionStorage.getItem('PrimeraPreguntaNavEnInternet') || '');
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editQuestOne, setEditQuestOne] = useState('');
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    setEditQuestOne(true);
    setPrimeraPreguntaNavEnInternet(val.value);
    let products = [];
    if (val.value === 'Navegar por páginas web') {
      products = data.filter(
        (e) => e.Name.startsWith('User Testing')
          || e.Name.startsWith('Uxline')
          || e.Name.startsWith('Gift hunter club'),
      );
      setDescIntro(
        'Gana dinero por navegar en internet y descubrir errores en páginas web, inscríbete aquí:',
      );
    }
    if (val.value === 'Explorar y descargar nuevas apps') {
      products = data.filter(
        (e) => e.Name.startsWith('Cash App') || e.Name.startsWith('Play Google'),
      );
      setDescIntro('Gana dinero por descargar y probar nuevas apps');
    }
    if (val.value === 'Revisar emails') {
      products = data.filter((e) => e.Name.startsWith('Consupermiso'));
      setDescIntro('Gana dinero revisando emails, inscríbete aquí:');
    }
    if (val.value === 'Pasar tiempo en las redes sociales') {
      products = data.filter(
        (e) => e.Name.startsWith('SocialPubli') || e.Name.startsWith('Publisuites'),
      );
      setDescIntro(
        'Aprovecha a  tus seguidores, conviértete en influencer o embajador de tus marcas favoritas y gana dinero, descubre cómo aquí:',
      );
    }
    if (val.value === 'Entiendo y me gustan los juegos de apuestas') {
      products = data.filter(
        (e) => e.Name.startsWith('Caliente MX')
          || e.Name.startsWith('Bet 365')
          || e.Name.startsWith('Ganabet')
          || e.Name.startsWith('Codere MX')
          || e.Name.startsWith('Betway MX')
          || e.Name.startsWith('Winner.com'),
      );
      setDescIntro(
        'Si te gustan los juegos de apuestas, prueba suerte con tu juego favorito. Descubre todas páginas que te permiten jugar con la opción de ganar desde tu casa:',
      );
    }
    setExtraIncomeProducts(products);
    gsap.to(window, { duration: 1, scrollTo: '#activeNavegar2' });
  };
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          // setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;

          const answers = {
            1: '',
          };

          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionOrder } = arrayResponseRutine[i];
              const { questionType } = arrayResponseRutine[i];
              if (responseUser && responseUser !== '') {
                answers[questionOrder] = responseUser;
              }

              if (questionType === 'Cat') {
                const { detailQuestion } = routines[idRoutine].questions[i];
                for (let x = 0; x < detailQuestion.length; x++) {
                  if (detailQuestion[x].FieldOption === responseUser) {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              } else {
                contResponse++;
                sessionStorage.setItem(
                  arraySessionsRutines[idTypeQuestion],
                  responseUser,
                );
              }
            }

            let products = [];

            if (answers[1] !== '') {
              setPrimeraPreguntaNavEnInternet(answers[1]);
              if (answers[1] === 'Navegar por páginas web') {
                products = data.filter(
                  (e) => e.Name.startsWith('User Testing')
                    || e.Name.startsWith('Uxline')
                    || e.Name.startsWith('Gift hunter club'),
                );
                setDescIntro(
                  'Gana dinero por navegar en internet y descubrir errores en páginas web, inscríbete aquí:',
                );
              }
              if (answers[1] === 'Explorar y descargar nuevas apps') {
                products = data.filter(
                  (e) => e.Name.startsWith('Cash App')
                    || e.Name.startsWith('Play Google'),
                );
                setDescIntro('Gana dinero por descargar y probar nuevas apps');
              }
              if (answers[1] === 'Revisar emails') {
                products = data.filter((e) => e.Name.startsWith('Consupermiso'));
                setDescIntro('Gana dinero revisando emails, inscríbete aquí:');
              }
              if (answers[1] === 'Pasar tiempo en las redes sociales') {
                products = data.filter(
                  (e) => e.Name.startsWith('SocialPubli')
                    || e.Name.startsWith('Publisuites'),
                );
                setDescIntro(
                  'Aprovecha a  tus seguidores, conviértete en influencer o embajador de tus marcas favoritas y gana dinero, descubre cómo aquí:',
                );
              }
              if (
                answers[1] === 'Entiendo y me gustan los juegos de apuestas'
              ) {
                products = data.filter(
                  (e) => e.Name.startsWith('Caliente MX')
                    || e.Name.startsWith('Bet 365')
                    || e.Name.startsWith('Ganabet')
                    || e.Name.startsWith('Codere MX')
                    || e.Name.startsWith('Betway MX')
                    || e.Name.startsWith('Winner.com'),
                );
                setDescIntro(
                  'Si te gustan los juegos de apuestas, prueba suerte con tu juego favorito. Descubre todas páginas que te permiten jugar con la opción de ganar desde tu casa:',
                );
              }
              setExtraIncomeProducts(products);
              gsap.to(window, { duration: 1, scrollTo: '#activeNavegar2' });
            }

            if (
              arrayResponseRutine.length <= routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              setQuestions([]);
              break;
            } else {
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    }
  }, []);

  const handleChangeIcon = (json) => {
    setEditQuestOne(false);
    setExtraIncomeProducts(null);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      if (orderRutine === currentRoutine) {
        gsap.to(window, { duration: 1, scrollTo: '#activeNavegarInternet' });
      }
    }, 4000);
  }, []);

  return (
    <Fragment>
      {!loaderWait || orderRutine !== 7 ? (
        <>
          {/* pregunta número uno */}
          <div id="activeNavegarInternet"></div>
          <QuestionsOrder
            classCard='cardOne'
            orderRutine={currentRoutine}
            step={0}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            className={
              (primeraPreguntaNavEnInternet === ''
                || (lapiz[0].routine === 7 && lapiz[0].active === 1))
              && editQuestOne !== true
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="activeNavegar2"></div>
          <CardTwo
            classCard={
              primeraPreguntaNavEnInternet !== ''
              && (editQuestOne === true
                || !(lapiz[0].routine === 7 && lapiz[0].active === 1))
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={primeraPreguntaNavEnInternet}
            colorTraining={trainingColor}
          >
            <img
              alt="editIcon"
              className={
                primeraPreguntaNavEnInternet !== ''
                  ? 'iconEditCommon'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 7, active: 1 })}
            />
          </CardTwo>
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
    </Fragment>
  );
};

export default NavegarEnInternet;
