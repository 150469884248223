/* eslint-disable react/prop-types */
import React, {useState} from "react";
import Parser from "html-react-parser";
import Carousel, {consts} from "react-elastic-carousel";
import {Button, Divider} from "@material-ui/core";
import {
  ContainerPage2,
  ContainerGeneral,
  Content1,
  SubContent,
  TextBase,
  TextTitle1,
  GridContent,
  HoverImageContainer,
  ContentCardP,
  HoverImg,
  ImgProduc,
  SubContentColor,
  TextGeneric,
  ContentCardExp,
  StartedButton,
} from "../Assets/Styles/CaasNew.styles";
import Cel from "../Assets/Imgs/Cel.svg";
import Cabeza from "../Assets/Imgs/Cabeza.svg";
import Card from "../Assets/Imgs/Card.svg";
import AiPersonalizada from "../Assets/Imgs/AiPersonalizada.svg";
import DigitalOnboarding from "../Assets/Imgs/DigitalOnboarding.svg";
import Userdiagnostic from "../Assets/Imgs/Userdiagnostic.svg";
import FinancialGamification from "../Assets/Imgs/FinancialGamification.svg";
import BienestarFinanciero from "../Assets/Imgs/BienestarFinanciero.svg";
import Algoritmo from "../Assets/Imgs/Algorithm.svg";
import {ContentGeneric} from "../../common/common.styled";

const CoruService = ({
  i1,
  i2,
  i3,
  i4,
  i5,
  i6,
  i7,
  i8,
  i9,
  i10,
  i11,
  i12,
  i13,
  i14,
  i15,
  i16,
  i17,
  i18,
  i19,
  i20,
  i21,
  i22,
  i23,
  i24,
  i25,
  i26,
  i27,
  i28,
  i29,
  i30,
  i31,
  i32,
  i33,
  i34,
  i35,
  i36,
  i37,
  i38,
  i39,
  i40,
  i41,
  i42,
}) => {
  const [Tupersonalizada, setTupersonalizada] = useState(false);
  const [OnboardingDigital, setOnboardingDigital] = useState(false);
  const [gamificacion, setGamificacion] = useState(false);
  const [Diagnostico, setDiagnostico] = useState(false);
  const [algoritmo, setAlgoritmo] = useState(false);
  const [bienestar, setBienestar] = useState(false);
  const [viewProductsMobile, setviewProductsMobile] = useState(false);
  const widthWindow = window.screen.availWidth;

  const IaCard = () => (
    <>
      <ImgProduc src={AiPersonalizada} />
      <TextGeneric
        color="#464646"
        msize="24px"
        fontSize={!Tupersonalizada ? "24px" : "16px"}
        line="120%"
        fontWeight="600"
        textAlign="center"
        padding="0"
        margin="18px 0">
        {i14}
      </TextGeneric>
      <SubContent width="80%" color="#464646" msize="12px" size="13px" margin="5px 0 0 0">
        {i20}
      </SubContent>
      <SubContent
        onClick={() => setTupersonalizada(!Tupersonalizada)}
        color="#F64282"
        msize="13.5px"
        size="13px"
        // margin='18px 0px 0px 0px'
        width="80%"
        cursor="pointer"
        display="flex"
        height="100%"
        alignItem="end">
        {Tupersonalizada ? i41 : i42}
      </SubContent>
    </>
  );

  const OnboardingCard = () => (
    <>
      <ImgProduc src={DigitalOnboarding} />
      <TextGeneric
        color="#464646"
        msize="24px"
        fontSize={!OnboardingDigital ? "24px" : "16px"}
        line="120%"
        fontWeight="600"
        textAlign="center"
        padding="0"
        margin="18px 0">
        {i15}
      </TextGeneric>
      <SubContent
        color="#464646"
        msize="12px"
        size="13px"
        width="80%"
        margin="5px 0 0 0">
        {i21}
      </SubContent>
      <SubContent
        onClick={() => setOnboardingDigital(!OnboardingDigital)}
        color="#F64282"
        msize="13.5px"
        size="13px"
        // margin='18px 0px 0px 0px'
        width="80%"
        cursor="pointer"
        display="flex"
        height="100%"
        alignItem="end">
        {OnboardingDigital ? i41 : i42}
      </SubContent>
    </>
  );

  const DiagnosticCard = () => (
    <>
      <ImgProduc src={Userdiagnostic} />
      <TextGeneric
        color="#464646"
        msize="24px"
        fontSize={!Diagnostico ? "24px" : "16px"}
        line="120%"
        fontWeight="600"
        textAlign="left"
        padding="0"
        margin="18px 0">
        {i16}
      </TextGeneric>
      <SubContent width="80%" color="#464646" msize="12px" size="13px">
        {i22}
      </SubContent>
      <SubContent
        onClick={() => setDiagnostico(!Diagnostico)}
        color="#F64282"
        msize="13.5px"
        size="13px"
        // margin='18px 0px 0px 0px'
        width="80%"
        cursor="pointer"
        display="flex"
        height="100%"
        alignItem="end">
        {Diagnostico ? i41 : i42}
      </SubContent>
    </>
  );

  const GamificationCard = () => (
    <>
      <ImgProduc src={FinancialGamification} />
      <TextGeneric
        color="#464646"
        msize="24px"
        fontSize={!gamificacion ? "24px" : "16px"}
        margin="18px 0"
        line="120%"
        fontWeight="600"
        textAlign="left"
        padding="0">
        {i17}
      </TextGeneric>
      <SubContent width="80%" color="#464646" msize="12px" size="13px">
        {i23}
      </SubContent>
      <SubContent
        onClick={() => setGamificacion(!gamificacion)}
        color="#F64282"
        msize="13.5px"
        size="13px"
        // margin='18px 0px 0px 0px'
        width="100%"
        cursor="pointer"
        display="flex"
        height="100%"
        alignItem="end">
        {gamificacion ? i41 : i42}
      </SubContent>
    </>
  );

  const AlgorithmCard = () => (
    <>
      <ImgProduc src={Algoritmo} />
      <TextGeneric
        color="#464646"
        msize="24px"
        fontSize={!algoritmo ? "24px" : "16px"}
        margin="18px 0"
        line="120%"
        fontWeight="600"
        textAlign="center"
        padding="0">
        {i18}
      </TextGeneric>
      <SubContent width="80%" color="#464646" msize="12px" size="13px">
        {i24}
      </SubContent>
      <SubContent
        onClick={() => setAlgoritmo(!algoritmo)}
        color="#F64282"
        msize="13.5px"
        size="13px"
        margin="18px 0px 0px 0px"
        width="100%"
        cursor="pointer"
        display="flex"
        height="100%"
        alignItem="end">
        {algoritmo ? i41 : i42}
      </SubContent>
    </>
  );

  const BienestarCard = () => (
    <>
      <ImgProduc src={BienestarFinanciero} />
      <TextGeneric
        color="#464646"
        msize="24px"
        fontSize={!bienestar ? "24px" : "16px"}
        margin="18px 0"
        line="120%"
        fontWeight="600"
        textAlign="center"
        padding="0">
        {i19}
      </TextGeneric>
      <SubContent width="80%" color="#464646" msize="12px" size="13px">
        {i39}
      </SubContent>
      <SubContent
        onClick={() => setBienestar(!bienestar)}
        color="#F64282"
        msize="13.5px"
        size="13px"
        // margin='18px 0px 0px 0px'
        width="100%"
        cursor="pointer"
        display="flex"
        height="100%"
        alignItem="end">
        {bienestar ? i41 : i42}
      </SubContent>
    </>
  );

  const myArrow = ({type, onClick, isEdge}) => {
    const pointer = type === consts.PREV ? "<" : ">";
    return (
      <Button
        onClick={onClick}
        disabled={isEdge}
        size="small"
        style={{minWidth: "20px"}}>
        <p style={{fontSize: "20px", color: "#F64282", fontFamily: "arial"}}>
          {pointer}
        </p>
      </Button>
    );
  };

  return (
    <ContainerPage2>
      <ContainerGeneral
        direction="row"
        padding="136px 160px"
        justify="space-between"
        mpad="83px 24px 40px"
        mobilPadding="100.03px 16px 60px"
        display="block">
        <Content1>
          { /* <SubContent width="-webkit-fill-available" color="#F64282" msize="20px">
            {i1}
          </SubContent>*/ }
          <TextTitle1 weight="600" size="48px" margin="28px 0px 0px 0px">
            {i2}
          </TextTitle1>
          <SubContent margin="35px 0 0">{i3}</SubContent>
        </Content1>
        <Content1 display="flex" widthD="100%" className="row">
          <GridContent className="col-md-4 col-12">
            <HoverImageContainer>
              <HoverImg alt="" src={Cel} />
            </HoverImageContainer>
            <TextBase
              color="#F64282"
              textAlign="center"
              fontSize="20px"
              margin="12px 0px 0px 0px"
              fontWeight="600">
              {i6}
            </TextBase>
            <TextBase
              color="#464646"
              textAlign="center"
              fontSize="12px"
              fontWeight="400"
              width="256px"
              display="flex"
              margin="16px auto auto auto">
              {i9}
            </TextBase>
          </GridContent>
          <GridContent className="col-md-4 col-12">
            <HoverImageContainer>
              <HoverImg alt="" src={Cabeza} />
            </HoverImageContainer>
            <TextBase
              color="#F64282"
              textAlign="center"
              fontSize="20px"
              margin="12px 0px 0px 0px"
              fontWeight="600">
              {i7}
            </TextBase>
            <TextBase
              color="#464646"
              textAlign="center"
              fontSize="12px"
              fontWeight="400"
              width="70%"
              display="flex"
              margin="16px auto auto auto">
              {i10}
            </TextBase>
          </GridContent>
          <GridContent className="col-md-4 col-12">
            <HoverImageContainer>
              <HoverImg alt="" src={Card} />
            </HoverImageContainer>
            <TextBase
              color="#F64282"
              textAlign="center"
              fontSize="20px"
              margin="12px 0px 0px 0px"
              fontWeight="600">
              {i8}
            </TextBase>
            <TextBase
              color="#464646"
              textAlign="center"
              fontSize="12px"
              fontWeight="400"
              width="70%"
              display="flex"
              margin="16px auto auto auto">
              {i11}
            </TextBase>
          </GridContent>
        </Content1>
      </ContainerGeneral>
      {/* -------------seccion 3 nuestros productos------------- */}
      <ContainerGeneral
        align="flex-start"
        direction="row"
        padding="40px"
        justify="space-between"
        mpad={widthWindow <= 768 ? "24px 0" : "24px"}
        talign="center"
        margin="1100% 0px 0px 0px"
        marginTablet="100px 0px 0px 0px"
        style={{gap: "0 40px"}}
        background="#FDF5F9"
        borderTop="dashed"
        borderWidth="1px"
        borderColor="#F64282"
        display="block">
        <ContainerGeneral
          align="flex-start"
          direction="row"
          padding="0px 50px"
          display="block">
          <SubContent color="#F64282" msize="20px">
            {i4}
          </SubContent>
          <TextTitle1 weight="600" size="48px" margin="28px 0px 0px 0px">
            {i5}
          </TextTitle1>
          <SubContent margin="16px 0 0 0">{i12}</SubContent>
          {/* <TextGeneric
            padding="30px 0px"
            justifyContent="flex-start"
            color="#F64282"
            fontSize="20px"
            fontWeight="600">
            {i13}
        </TextGeneric>*/ }
        </ContainerGeneral>
        {/* diseño de Mobile, solo colocar la parte de Desk en lugar del null */}
        {widthWindow <= 768 ? (
          <>
            <ContainerGeneral>
              {!Tupersonalizada ? (
                <ContentCardExp height="auto" padding="12px 24px">
                  <div>
                    {" "}
                    <ImgProduc src={AiPersonalizada} />
                  </div>
                  <div style={{ marginLeft: "10px", }}>
                    <TextGeneric
                      color="#464646"
                      msize="24px"
                      fontSize="18px"
                      fontWeight="600"
                      padding="0"
                      justifyContent="start">
                      {i14}
                    </TextGeneric>
                    <SubContent color="#464646" size="12px" margin="8px 0 0 0">
                      {i20}
                    </SubContent>
                    <SubContent
                      onClick={() => setTupersonalizada(!Tupersonalizada)}
                      color="#F64282"
                      msize="14px"
                      size="14px"
                      margin="18px 0px 0px 0px"
                      width="100%">
                      {Tupersonalizada ? i41 : i42}
                    </SubContent>{" "}
                  </div>
                </ContentCardExp>
              ) : (
                <ContentCardExp height="auto" padding="12px 12px 20px 24px">
                  <div>
                    <ImgProduc src={AiPersonalizada} />
                  </div>
                  <div>
                    <SubContent msize="12px">
                      <SubContentColor>{i25}</SubContentColor>
                      {i29}
                      <br /> <br />
                      {Parser(i33)}
                    </SubContent>
                    <SubContent
                      onClick={() => setTupersonalizada(!Tupersonalizada)}
                      color="#F64282"
                      msize="14px"
                      size="14px"
                      margin="18px 0px 0px 0px"
                      width="100%">
                      {Tupersonalizada ? i41 : i42}{" "}
                    </SubContent>
                  </div>
                </ContentCardExp>
              )}
              {!OnboardingDigital ? (
                <ContentCardExp height="auto" padding="12px 24px">
                  <div>
                    {" "}
                    <ImgProduc src={DigitalOnboarding} />
                  </div>
                  <div style={{marginLeft: "10px"}}>
                    <TextGeneric
                      color="#464646"
                      msize="24px"
                      fontSize="18px"
                      fontWeight="600"
                      padding="0"
                      justifyContent="start">
                      {i15}
                    </TextGeneric>
                    <SubContent color="#464646" size="12px" margin="8px 0 0 0">
                      {i21}
                    </SubContent>
                    <SubContent
                      onClick={() => setOnboardingDigital(!OnboardingDigital)}
                      color="#F64282"
                      msize="14px"
                      size="14px"
                      margin="18px 0px 0px 0px"
                      width="100%">
                      {OnboardingDigital ? i41 : i42}
                    </SubContent>{" "}
                  </div>
                </ContentCardExp>
              ) : (
                <ContentCardExp height="auto" padding="12px 12px 20px 24px">
                  <div>
                    <ImgProduc src={DigitalOnboarding} />
                  </div>
                  <div>
                    <SubContent msize="12px">
                      {i30}
                      <SubContentColor>{i26}</SubContentColor>
                      <br /> <br />
                      {Parser(i34)}
                    </SubContent>
                    <SubContent
                      onClick={() => setOnboardingDigital(!OnboardingDigital)}
                      color="#F64282"
                      msize="14px"
                      size="14px"
                      margin="18px 0px 0px 0px"
                      width="100%">
                      {OnboardingDigital ? i41 : i42}{" "}
                    </SubContent>
                  </div>
                </ContentCardExp>
              )}
              {!Diagnostico ? (
                <ContentCardExp height="auto" padding="12px 24px">
                  <div>
                    {" "}
                    <ImgProduc src={Userdiagnostic} />
                  </div>
                  <div style={{marginLeft: "10px"}}>
                    <TextGeneric
                      color="#464646"
                      msize="24px"
                      fontSize="18px"
                      fontWeight="600"
                      padding="0"
                      justifyContent="start">
                      {i16}
                    </TextGeneric>
                    <SubContent color="#464646" size="12px" margin="8px 0 0 0">
                      {i22}
                    </SubContent>
                    <SubContent
                      onClick={() => setDiagnostico(!Diagnostico)}
                      color="#F64282"
                      msize="14px"
                      size="14px"
                      margin="18px 0px 0px 0px"
                      width="100%">
                      {Diagnostico ? i41 : i42}
                    </SubContent>{" "}
                  </div>
                </ContentCardExp>
              ) : (
                <ContentCardExp height="auto" padding="12px 12px 20px 24px">
                  <div>
                    <ImgProduc src={Userdiagnostic} />
                  </div>
                  <div>
                    <SubContent msize="12px">
                      {i31}
                      <SubContentColor> {i27}</SubContentColor>
                      <br /> <br />
                      {Parser(i22)}
                    </SubContent>
                    <SubContent
                      onClick={() => setDiagnostico(!Diagnostico)}
                      color="#F64282"
                      msize="14px"
                      size="14px"
                      margin="18px 0px 0px 0px"
                      width="100%">
                      {Diagnostico ? i41 : i42}{" "}
                    </SubContent>
                  </div>
                </ContentCardExp>
              )}
              {!gamificacion ? (
                <ContentCardExp height="auto" padding="12px 24px">
                  <div>
                    {" "}
                    <ImgProduc src={FinancialGamification} />
                  </div>
                  <div style={{marginLeft: "10px"}}>
                    <TextGeneric
                      color="#464646"
                      msize="24px"
                      fontSize="18px"
                      fontWeight="600"
                      padding="0"
                      justifyContent="start">
                      {i17}
                    </TextGeneric>
                    <SubContent color="#464646" size="12px" margin="8px 0 0 0">
                      {i23}
                    </SubContent>
                    <SubContent
                      onClick={() => setGamificacion(!gamificacion)}
                      color="#F64282"
                      msize="14px"
                      size="14px"
                      margin="18px 0px 0px 0px"
                      width="100%">
                      {gamificacion ? i41 : i42}
                    </SubContent>{" "}
                  </div>
                </ContentCardExp>
              ) : (
                <ContentCardExp height="auto" padding="12px 12px 20px 24px">
                  <div>
                    <ImgProduc src={FinancialGamification} />
                  </div>
                  <div>
                    <SubContent msize="12px">
                      {i32}
                      <SubContentColor> {i28}</SubContentColor>
                      <br /> <br />
                      {Parser(i36)}
                    </SubContent>
                    <SubContent
                      onClick={() => setGamificacion(!gamificacion)}
                      color="#F64282"
                      msize="14px"
                      size="14px"
                      margin="18px 0px 0px 0px"
                      width="100%">
                      {gamificacion ? i41 : i42}{" "}
                    </SubContent>
                  </div>
                </ContentCardExp>
              )}
              {viewProductsMobile ? (
                <>
                  {!algoritmo ? (
                    <ContentCardExp height="auto" padding="12px 24px">
                      <div>
                        {" "}
                        <ImgProduc src={Algoritmo} />
                      </div>
                      <div style={{marginLeft: "10px"}}>
                        <TextGeneric
                          color="#464646"
                          msize="24px"
                          fontSize="18px"
                          fontWeight="600"
                          padding="0"
                          justifyContent="start">
                          {i18}
                        </TextGeneric>
                        <SubContent
                          color="#464646"
                          size="12px"
                          margin="8px 0 0 0">
                          {i24}
                        </SubContent>
                        <SubContent
                          onClick={() => setAlgoritmo(!algoritmo)}
                          color="#F64282"
                          msize="14px"
                          size="14px"
                          margin="18px 0px 0px 0px"
                          width="100%">
                          {algoritmo ? i41 : i42}
                        </SubContent>{" "}
                      </div>
                    </ContentCardExp>
                  ) : (
                    <ContentCardExp height="auto" padding="12px 12px 20px 24px">
                      <div>
                        <ImgProduc src={Algoritmo} />
                      </div>
                      <div>
                        <SubContent msize="12px">{Parser(i37)}</SubContent>
                        <SubContent
                          onClick={() => setAlgoritmo(!algoritmo)}
                          color="#F64282"
                          msize="14px"
                          size="14px"
                          margin="18px 0px 0px 0px"
                          width="100%">
                          {algoritmo ? i41 : i42}{" "}
                        </SubContent>
                      </div>
                    </ContentCardExp>
                  )}
                  {!bienestar ? (
                    <ContentCardExp height="auto" padding="12px 24px">
                      <div>
                        {" "}
                        <ImgProduc src={BienestarFinanciero} />
                      </div>
                      <div style={{marginLeft: "10px"}}>
                        <TextGeneric
                          color="#464646"
                          msize="24px"
                          fontSize="18px"
                          fontWeight="600"
                          padding="0"
                          justifyContent="start">
                          {i19}
                        </TextGeneric>
                        <SubContent
                          color="#464646"
                          size="12px"
                          margin="8px 0 0 0">
                          {i39}
                        </SubContent>
                        <SubContent
                          onClick={() => setBienestar(!bienestar)}
                          color="#F64282"
                          msize="14px"
                          size="14px"
                          margin="18px 0px 0px 0px"
                          width="100%">
                          {bienestar ? i41 : i42}
                        </SubContent>{" "}
                      </div>
                    </ContentCardExp>
                  ) : (
                    <ContentCardExp height="auto" padding="12px 12px 20px 24px">
                      <div>
                        <ImgProduc src={BienestarFinanciero} />
                      </div>
                      <div>
                        <SubContent msize="12px">{Parser(i38)}</SubContent>
                        <SubContent
                          onClick={() => setBienestar(!bienestar)}
                          color="#F64282"
                          msize="14px"
                          size="14px"
                          margin="18px 0px 0px 0px"
                          width="100%">
                          {bienestar ? i41 : i42}{" "}
                        </SubContent>
                      </div>
                    </ContentCardExp>
                  )}
                </>
              ) : null}
            </ContainerGeneral>
            <ContainerGeneral marginSm="24px auto 0px auto">
              <StartedButton
                onClick={() => setviewProductsMobile(!viewProductsMobile)}>
                {viewProductsMobile ? i41 : i40}
              </StartedButton>
            </ContainerGeneral>
          </>
        ) : (
          <ContainerGeneral
            align="flex-start"
            direction="row"
            padding="0 10px"
            display="block"
            id="CarruselProductos">
            <Carousel
              renderArrow={myArrow}
              showArrows={true}
              itemsToShow={4}
              pagination={false}
              itemPosition={consts.START}
              itemPadding={[0, 5]}
              style={{ background: "transparent", height: "auto" }}>
              {!Tupersonalizada ? (
                <ContentCardP>
                  <IaCard />
                </ContentCardP>
              ) : (
                <ContentCardExp>
                  <div>
                    <IaCard />
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{margin: "0 13px"}}
                  />
                  <div style={{ width: "250%" }}>
                    <SubContent size="11px">
                      <SubContentColor>{i25}</SubContentColor>
                      {i29}
                      <br /> <br />
                      {Parser(i33)}
                    </SubContent>
                  </div>
                </ContentCardExp>
              )}
              {!OnboardingDigital ? (
                <ContentCardP>
                  <OnboardingCard />
                </ContentCardP>
              ) : (
                <ContentCardExp>
                  <div>
                    <OnboardingCard />
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{margin: "0 13px"}}
                  />
                  <div style={{ width: "250%" }}>
                    <SubContent size="11px">
                      {i30}
                      <SubContentColor>{i26}</SubContentColor>
                      <br /> <br />
                      {Parser(i34)}
                    </SubContent>
                  </div>
                </ContentCardExp>
              )}
              {!Diagnostico ? (
                <ContentCardP>
                  <DiagnosticCard />
                </ContentCardP>
              ) : (
                <ContentCardExp>
                  <div>
                    <DiagnosticCard />
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{ margin: "0 12px" }}
                  />
                  <div style={{ width: "250%" }}>
                    <SubContent size="11px">
                      {i31}
                      <SubContentColor> {i27}</SubContentColor>
                      <br /> <br />
                      {Parser(i35)}
                    </SubContent>
                  </div>
                </ContentCardExp>
              )}
              {!gamificacion ? (
                <ContentCardP>
                  <GamificationCard />
                </ContentCardP>
              ) : (
                <ContentCardExp>
                  <div>
                    <GamificationCard />
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{margin: "0 12px"}}
                  />
                  <div style={{ width: "250%" }}>
                    <SubContent size="11px">
                      {i32}
                      <SubContentColor> {i28} </SubContentColor>
                      <br /> <br />
                      {Parser(i36)}
                    </SubContent>
                  </div>
                </ContentCardExp>
              )}
              {!algoritmo ? (
                <ContentCardP>
                  <AlgorithmCard />
                </ContentCardP>
              ) : (
                <ContentCardExp>
                  <div>
                    <AlgorithmCard />
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{margin: "0 9px"}}
                  />
                  <div style={{ width: "250%" }}>
                    <SubContent size="11px">{Parser(i37)}</SubContent>
                  </div>
                </ContentCardExp>
              )}
              {!bienestar ? (
                <ContentCardP>
                  <BienestarCard />
                </ContentCardP>
              ) : (
                <ContentCardExp>
                  <div>
                    <BienestarCard />
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{margin: "0 12px"}}
                  />
                  <div style={{ width: "250%" }}>
                    <SubContent size="11px">{Parser(i38)}</SubContent>
                  </div>
                </ContentCardExp>
              )}
              <ContentCardP color="transparent" shadow=" 0"/>
              <ContentCardP color="transparent" shadow=" 0"/>
            </Carousel>
          </ContainerGeneral>
        )}
      </ContainerGeneral>
    </ContainerPage2>
  );
};

export default CoruService;
