import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useMutation } from '@apollo/client';
// Other components
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import SectionAdvance from '../../components/Training/AdvanceBudget';
import {
  filterFloat,
  formatterMoney,
} from '../../Helpers';
import ClickButton from '../../Helpers/HookHelpers';
import { INSERT_DAT_GOAL } from '../../mutations';
import {
  TrainingQuestion,
  CardsBtn,
  CardsBtn1,
  TrainingSubtitles,
  TrainingDivSection,
  TrainingBenef,
  TrainingSectionGray,
  TrainingDivSection2,
} from '../../assets/styles/Training.styled';
import {
  Col12,
  Container,
  Col2,
  Col10,
  DivGoals,
  DivContainerGoal,
  DivContainerTextGoal,
} from '../../components/common/common.styled';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const SavingsAmountRecord = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  setValidateStatusTraining,
  handelChangeRecoveryHistory,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [open, setOpen] = React.useState(false);
  const [feacturesArray, setFeacturesArray] = useState([]);
  const [ahorro, setAhorro] = useState('');
  const [concepto, setConcepto] = useState('');
  const [metas, setMetas] = useState([]);
  const [montoMeta] = useState(sessionStorage.getItem('MontoMeta') || '');
  const [ahorroMensual] = useState(
    sessionStorage.getItem('AhorroMensual') || '',
  );
  const [tiempodeAhorro] = useState(
    sessionStorage.getItem('TiempodeAhorro') || '',
  );
  useEffect(() => {
    setFeacturesArray([
      {
        text: `Ahorra ${
          sessionStorage.getItem('DineroMetaQuincenal') || ''
        } cada quincena durante ${
          sessionStorage.getItem('TiempodeAhorro') || ''
        } meses.`,
        color: '#E3E660',
      },
      {
        text: 'Registra tu ahorro quincenal en Coru para dar seguimiento a tu meta.',
        color: '#E3E660',
      },
      {
        text: 'Una vez que cumplas tu meta te recomiendo crear un portafolio de inversiones para fortalecer tus ingresos.',
        color: '#E3E660',
      },
    ]);
    const seleccionMeta = sessionStorage.getItem('SeleccionaMeta') || '';

    if (seleccionMeta !== '') {
      let goals = String(seleccionMeta).replace(/\|/g, ',');
      goals = goals.split(',');
      setMetas(goals);
    }
  }, []);

  const [insertDatGoal] = useMutation(INSERT_DAT_GOAL, {
    onCompleted({ insertDatGoal }) {
      if (insertDatGoal.message === 'success') {
        console.log('insercion exitossa, ID = ', insertDatGoal);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (metas.length > 0) {
      const idUser = parseInt(sessionStorage.getItem('idUser'));
      const idCatTraining = currentTraining || parseInt(sessionStorage.getItem('idTraining'));
      for (let j = 0; j < metas.length; j++) {
        let objTypes = [];
        if (j === 0) {
          objTypes = [
            {
              idType: '343',
              value: sessionStorage.getItem('MontoMeta'),
            },
            {
              idType: '344', // fechaMeta
              value: sessionStorage.getItem('DineroMetaQuincenal'),
            },
            {
              idType: '345', // fechaMeta
              value: sessionStorage.getItem('AhorroMensual'),
            },
            {
              idType: '346', // fechaMeta
              value: sessionStorage.getItem('TiempodeAhorro'),
            },
          ];
        }
        const inputInsert = {
          input: {
            idUser,
            idTraining: idCatTraining,
            name: metas[j],
            goalsDetails: objTypes,
            saveAmount: '',
          },
        };
        // debugger;
        insertDatGoal({
          variables: inputInsert,
        });
      }
    }
  }, [metas]);
  const handleClickOpen = () => {
    const click = `RegistrarAhorro_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setOpen(true);
  };

  const handleClickSaveAhorro = () => {
    const click = `GuardarAhorro_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    const idUser = parseInt(sessionStorage.getItem('idUser'));
    const idCatTraining = currentTraining || parseInt(sessionStorage.getItem('idTraining'));
    const newAhorro = parseFloat(
      ahorro.replace('$', '').replace(/,/g, '').trim(),
    );
    const monto = newAhorro > 0 ? String(newAhorro.toFixed(2)) : '';
    const inputInsert = {
      input: {
        idUser,
        idTraining: idCatTraining,
        name: metas[0],
        goalsDetails: [
          {
            idType: '343',
            value: sessionStorage.getItem('MontoMeta'),
          },
          {
            idType: '344', // fechaMeta
            value: sessionStorage.getItem('DineroMetaQuincenal'),
          },
          {
            idType: '345', // fechaMeta
            value: sessionStorage.getItem('AhorroMensual'),
          },
          {
            idType: '346', // fechaMeta
            value: sessionStorage.getItem('TiempodeAhorro'),
          },
        ],
        saveAmount: monto,
        description: concepto,
      },
    };
    // debugger;
    insertDatGoal({
      variables: inputInsert,
    });
    handelChangeRecoveryHistory(Math.floor(Math.random() * 100));

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const datum = [
    { key: 'Tu avance', y: Math.round(0), color: '#91D770' },
    { key: '', y: Math.round(100), color: '#F5F5F5' },
  ];
  const handelChangeAhorro = (e) => {
    let value = e.target.value.replace('$', '').replace(/,/g, '').trim();
    value = filterFloat(value);

    if (value !== '') {
      setAhorro(formatterMoney.format(filterFloat(value)));
    } else if (e.target.value === '$') {
      setAhorro('');
    }
  };
  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll && feacturesArray.length > 0 && metas.length > 0) {
        gsap.to(window, { duration: 1, scrollTo: '#activeEstrategias' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);
  return (
    <div>
      <div id="activeEstrategias"></div>
      {feacturesArray.length > 0 && metas.length > 0 ? (
        <TrainingSectionGray wiw="100%" mw="auto" back="#FAFAFA" mtop="0">
          <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
            <div className="marginComentLgSuccess1">
              <TrainingDivSection
                hew="auto"
                paddw="26px"
                wiw="60%"
                mlw="18%"
                back="#F3F3F3"
                backw="#F3F3F3"
                bshaw="none"
                bsha=""
                br="4px"
                padding="20px"
                wi="100%"
                he="auto"
                mb="0px"
                className="topOne1"
              >
                <TrainingSubtitles
                  size="20px"
                  fw="600"
                  align="left"
                  alignweb="left"
                  className="cabin"
                >
                  {`Estrategia ${metas[0]}`}
                </TrainingSubtitles>
                {feacturesArray.map((feacture, index) => (
                  <Container
                    width="100%"
                    mt="0rem"
                    widthMobile="100%"
                    mtMobile="0rem"
                  >
                    <Col2 display="flex" mt="initial">
                      <TrainingBenef
                        bbs="solid"
                        bbsw="none"
                        bbw="1px"
                        bbc="#CDCDCD"
                        pt="17px"
                        pb="17px"
                        he="auto"
                        pl="0px"
                        mt="0px"
                        className="roboto"
                        alignweb="center"
                        ls="-0.2px"
                        width="100%"
                      >
                        <CheckBoxIcon
                          style={{ color: feacture.color, fontSize: 23 }}
                        />
                      </TrainingBenef>
                    </Col2>
                    <Col10 padding="0px" pMobile="0px 15px">
                      <TrainingBenef
                        bbs="solid"
                        bbsw="none"
                        bbw="1px"
                        bbc="#CDCDCD"
                        pt="17px"
                        pb="17px"
                        he="auto"
                        pl="0px"
                        mt="0px"
                        className="roboto"
                        alignweb="left"
                        ls="-0.2px"
                      >
                        {feacture.text}
                      </TrainingBenef>
                    </Col10>
                  </Container>
                ))}
              </TrainingDivSection>
            </div>
          </TrainingDivSection2>
        </TrainingSectionGray>
      ) : null}

      <TrainingSectionGray wiw="100%" mw="auto" back="#FAFAFA" mtop="0">
        <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
          <div className="marginComentLgSuccess1 d-flex justify-content-center">
            <div
              className="d-flex justify-content-center"
              style={{ width: '60%' }}
            >
              <div className="col-4" display="flex" mt="initial">
                <DivContainerGoal>
                  <Container
                    width="100%"
                    mt="0rem"
                    widthMobile="100%"
                    mtMobile="0rem"
                  >
                    <Col12 display="flex" mt="initial">
                      <DivContainerTextGoal>
                        {tiempodeAhorro > 1
                          ? `${tiempodeAhorro} meses`
                          : `${tiempodeAhorro} mes`}
                      </DivContainerTextGoal>
                    </Col12>
                    <Col12 display="flex" mt="initial">
                      <DivGoals>Tiempo para meta</DivGoals>
                    </Col12>
                  </Container>
                </DivContainerGoal>
              </div>

              <div className="col-4" display="flex" mt="initial">
                <DivContainerGoal>
                  <Container
                    width="100%"
                    mt="0rem"
                    widthMobile="100%"
                    mtMobile="0rem"
                  >
                    <Col12 display="flex" mt="initial">
                      <DivContainerTextGoal>{montoMeta}</DivContainerTextGoal>
                    </Col12>
                    <Col12 display="flex" mt="initial">
                      <DivGoals>Monto de meta</DivGoals>
                    </Col12>
                  </Container>
                </DivContainerGoal>
              </div>
              <div className="col-4 " display="flex" mt="initial">
                <DivContainerGoal>
                  <Container
                    width="100%"
                    mt="0rem"
                    widthMobile="100%"
                    mtMobile="0rem"
                  >
                    <Col12 display="flex" mt="initial">
                      <DivContainerTextGoal>
                        {' '}
                        ${new Intl.NumberFormat('en-IN').format(ahorroMensual)}
                      </DivContainerTextGoal>
                    </Col12>
                    <Col12 display="flex" mt="initial">
                      <DivGoals>Ahorro mensual</DivGoals>
                    </Col12>
                  </Container>
                </DivContainerGoal>
              </div>
            </div>
          </div>
        </TrainingDivSection2>
      </TrainingSectionGray>
      <SectionAdvance
        datum={datum}
        avance={'0%'}
        tiempodeAhorro={tiempodeAhorro}
        goal={metas[0]}
      />

      <TrainingQuestion>
        <CardsBtn1 windthmd="100%" onClick={(e) => handleClickOpen(e)}>
          Registra ahorro
        </CardsBtn1>
      </TrainingQuestion>

      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Registra ahorro
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            variant="outlined"
            label="Concepto ahorro Ejem: Quincena 1"
            autoComplete="off"
            required
            fullWidth
            inputProps={{ maxLength: 20 }}
            className="textInput inputs-bottom input-coru"
            value={concepto}
            onBlur={(e) => setConcepto(e.target.value)}
            onChange={(e) => setConcepto(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Monto de ahorro"
            autoComplete="off"
            required
            fullWidth
            inputProps={{ maxLength: 10 }}
            className="textInput inputs-bottom input-coru"
            value={ahorro}
            onBlur={(e) => handelChangeAhorro(e)}
            onChange={(e) => handelChangeAhorro(e)}
          />
        </DialogContent>
        <DialogActions>
          <CardsBtn
            type="button"
            min_w="100%"
            min_web="100%"
            onClick={(e) => handleClickSaveAhorro(e)}
          >
            Guardar
          </CardsBtn>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SavingsAmountRecord;
