/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import {
  Card, CardsContainer, TextCard
} from '../WhyCoru/styles';
/* assets */
import MKTAutomationicon from '../Assets/Imgs/MKT_Automation_icon.svg';
import ADrivenDecisioicon from '../Assets/Imgs/ADrivenDecision_icon.svg';
import UserDiagnosticicon from '../Assets/Imgs/User_diagnostic_icon.svg';
import CustomizedDigitalEngineeringicon from '../Assets/Imgs/CustomizedDigitalEngineering_icon.svg';
import { Button2, ImgGeneric, TextGeneric } from '../Assets/Styles/CaasNew.styles';
import ClickButton from "../../../Helpers/HookHelpers";

const CapacidadesEsp = (
  {
    i1, i2, i3, i4, i5, i6, i7, i8, i9, i10, url
  }
) => {
  const [overCardId] = useState(0);
  const cardList = [
    {
      title: i2, description: i6, id: 1, img: MKTAutomationicon,
    },
    {
      title: i3, description: i7, id: 2, img: ADrivenDecisioicon
    },
    {
      title: i4, description: i8, id: 3, img: CustomizedDigitalEngineeringicon
    },
    {
      title: i5, description: i9, id: 4, img: UserDiagnosticicon
    },
  ];

  const { actions } = ClickButton();

  const handleClickCoru = () => {
    actions({
      action: 'ClickButtonLPCaaS',
      variables: `Click_Button_capacidadcrearadi`,
      collection: 'ClickButtonLPCaaS',
    });
    window.open(url, '_blank');
  };

  return (
    <>
        <TextGeneric
          padding="30px 0px"
          justifyContent="flex-start"
          color="rgba(57, 58, 128, 1)"
          fontSize="20px"
          fontWeight="600"
          >
          {i1}
        </TextGeneric>
        <CardsContainer display="grid" gridTemplateColumns="repeat(4, 2fr)" gridTemplateColumnsSm="repeat(1, 2fr)">
        {cardList.map((cardItem) => (
            <Card
                height="auto"
                padding="12px 17px"
                key={cardItem.title}
                isOver={overCardId === cardItem.id}
              >
                <ImgGeneric margin="10px" src={cardItem.img} width="60px" height="60px" alignSelf="center" />
                <TextCard fontWeight="600">{cardItem.title}</TextCard>
                <TextGeneric textAlign="left"> {cardItem.description}</TextGeneric>
            </Card>))}
        </CardsContainer>
        <Button2
            name='capacidadcrearadi'
            margin="32px 0 0"
            onClick={handleClickCoru}
        >
            {i10}
        </Button2>
    </>
  );
};

export default CapacidadesEsp;
