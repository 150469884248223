/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import LogoFooter from '../Assets/Imgs/LogoFooter.svg';
import LinkedinImage from '../Assets/Imgs/linkedin.svg';
import InstagramImg from '../Assets/Imgs/instagram.svg';
import LogoCoru from "../Assets/Imgs/coruCaas.svg";

import {
  Wrapper,
  LogoContainer,
  ColumnCoru,
  ColumnRelatedContent,
  ColumnFaq,
  TitleColumn,
  ListItems,
  Logo,
  Linkedin,
  Instagram,
  SocialContainer,
  ItemText,
} from './styles.jsx';
import { FooterVerticalsA } from '../../LpHomeCoru/Assets/styles/Footer.styled';

const Footer = ({
  i1, i2, i3, i4, i5, i6, i7, i8, i9, i10, i11, i12, i13, selectLang
}) => {
  const [urlcontactanos, seturlcontactanos] = useState(`https://api.whatsapp.com/send?phone=+5215644783641&text=Cómo puedo ponerme en contacto con ustedes?`);
  const [urlnosotros, seturlnosotros] = useState(`https://api.whatsapp.com/send?phone=+5215644783641&text=¡Hola!, ¿Qué es Coru?`);
  const [urlsoporte, seturlsoporte] = useState(`https://api.whatsapp.com/send?phone=+5215644783641&text=¿Cómo puedo obtener ayuda para mi servicio?`);
  const [urldiagnostico, seturldiagnostico] = useState(`https://api.whatsapp.com/send?phone=+5215644783641&text=¿Cómo funciona un diagnóstico de usuario?`);
  const [urlbeneficios, seturlbeneficios] = useState(`https://api.whatsapp.com/send?phone=+5215644783641&text=¿Qué beneficios tiene la gamificación?`);
  const [urldigital, seturldigital] = useState(`https://api.whatsapp.com/send?phone=+5215644783641&text=¿Qué es el onboarding digital?`);
  const [urlcontratar, seturlcontratar] = useState(`https://api.whatsapp.com/send?phone=+5215644783641&text=¿Cómo puedo contratar a Coru?`);
  const [urlinteresa, seturlinteresa] = useState(`https://api.whatsapp.com/send?phone=+5215644783641&text=Me interesa saber más sobre sus casos de éxito.`);
  const [urlbeneficiosui, seturlbeneficiosui] = useState(`https://api.whatsapp.com/send?phone=+5215644783641&text=¿Cuáles son los beneficios de Adi, el Asistente Digital de Coru?`);
  useEffect(() => {
    switch (selectLang) {
      case 1:
        seturlcontactanos(`https://api.whatsapp.com/send?phone=+5215644783641&text=¿Cómo puedo ponerme en contacto con ustedes?`);
        seturlnosotros(`https://api.whatsapp.com/send?phone=+5215644783641&text=¡Hola!, ¿Qué es Coru?`);
        seturlsoporte(`https://api.whatsapp.com/send?phone=+5215644783641&text=¿Cómo puedo obtener ayuda para mi servicio? Tengo una duda o problema, ¿en donde puedo contactarlos?`);
        seturldiagnostico(`https://api.whatsapp.com/send?phone=+5215644783641&text=¿Cómo funciona un diagnóstico de usuario?`);
        seturlbeneficios(`https://api.whatsapp.com/send?phone=+5215644783641&text=¿Qué beneficios tiene la gamificación?`);
        seturldigital(`https://api.whatsapp.com/send?phone=+5215644783641&text=¿Qué es el onboarding digital?`);
        seturlcontratar(`https://api.whatsapp.com/send?phone=+5215644783641&text=¿Cómo puedo contratar a Coru?`);
        seturlinteresa(`https://api.whatsapp.com/send?phone=+5215644783641&text=Me interesa saber más sobre sus casos de éxito.`);
        seturlbeneficiosui(`https://api.whatsapp.com/send?phone=+5215644783641&text=¿Cuáles son los beneficios de Adi, el Asistente Digital de Coru?`);
        break;
      case 2:
        seturlcontactanos(`https://api.whatsapp.com/send?phone=+5215644783641&text=How can I get in touch with you?`);
        seturlnosotros(`https://api.whatsapp.com/send?phone=+5215644783641&text=Hello! What is Coru?`);
        seturlsoporte(`https://api.whatsapp.com/send?phone=+5215644783641&text=How can I get assistance for my service? If I have a question or problem, where can I contact you?`);
        seturldiagnostico(`https://api.whatsapp.com/send?phone=+5215644783641&text=How does a user diagnosis work?`);
        seturlbeneficios(`https://api.whatsapp.com/send?phone=+5215644783641&text=What benefits does gamification have?`);
        seturldigital(`https://api.whatsapp.com/send?phone=+5215644783641&text=What is digital onboarding?`);
        seturlcontratar(`https://api.whatsapp.com/send?phone=+5215644783641&text=How do I hire Coru?`);
        seturlinteresa(`https://api.whatsapp.com/send?phone=+5215644783641&text=I'm interested in learning more about your success stories.`);
        seturlbeneficiosui(`https://api.whatsapp.com/send?phone=+5215644783641&text=What are the benefits of Adi, Coru's Digital Assistant?`);
        break;
      case 3:
        seturlcontactanos(`https://api.whatsapp.com/send?phone=+5215644783641&text=Como posso entrar em contato com vocês?`);
        seturlnosotros(`https://api.whatsapp.com/send?phone=+5215644783641&text=Olá! O que é o Coru?`);
        seturlsoporte(`https://api.whatsapp.com/send?phone=+5215644783641&text=Como posso obter ajuda com o meu serviço? Se eu tiver uma dúvida ou problema, onde posso entrar em contato com vocês?`);
        seturldiagnostico(`https://api.whatsapp.com/send?phone=+5215644783641&text=Como funciona um diagnóstico de usuário?`);
        seturlbeneficios(`https://api.whatsapp.com/send?phone=+5215644783641&text=Quais são os benefícios da gamificação?`);
        seturldigital(`https://api.whatsapp.com/send?phone=+5215644783641&text=O que é a incorporação digital?`);
        seturlcontratar(`https://api.whatsapp.com/send?phone=+5215644783641&text=Como eu contrato a Coru?`);
        seturlinteresa(`https://api.whatsapp.com/send?phone=+5215644783641&text=Estou interessado(a) em saber mais sobre seus casos de sucesso.`);
        seturlbeneficiosui(`https://api.whatsapp.com/send?phone=+5215644783641&text=Quais são os benefícios do Adi, o Assistente Digital da Coru?
        `);
        break;
      default: break;
    }
  }, [selectLang]);
  return (
        <Wrapper>
            <LogoContainer>
                <Logo src={LogoCoru} alt='' />
                <SocialContainer>
                    <Linkedin src={LinkedinImage} alt='' onClick={() => window.open('https://www.linkedin.com/company/coru-com')}/>
                </SocialContainer>
            </LogoContainer>
            <ColumnCoru>
                <TitleColumn id="Holñaaaaa">{i1}</TitleColumn>
                <ListItems>
                    <ItemText target="_blanck" href={urlnosotros}>{i2}</ItemText>
                    <ItemText target="_blanck" href={urlcontactanos}>
                        {i3}
                    </ItemText>
                    <ItemText target="_blanck" href={urlsoporte}>
                        {i4}
                    </ItemText>
                </ListItems>
            </ColumnCoru>
            <ColumnRelatedContent>
                <TitleColumn>{i5}</TitleColumn>
                <ListItems>
                    <ItemText target="_blanck" href={urlbeneficiosui}>{i6}</ItemText>
                    <ItemText target="_blanck" href={urldiagnostico}>{i7}</ItemText>
                    <ItemText target="_blanck" href={urlbeneficios}>{i8}</ItemText>
                    <ItemText target="_blanck" href={urldigital}>{i9}</ItemText>
                </ListItems>
            </ColumnRelatedContent>
            <ColumnFaq>
                <TitleColumn>{i10}</TitleColumn>
                <ListItems>
                    <ItemText target="_blanck" href={urlcontratar}>{i11}</ItemText>
                    <ItemText target="_blanck" href={urlinteresa}>{i12}</ItemText>
                    <ItemText target="_blanck" href="https://coru.com/privacy-notice">Aviso de privacidad Coru 2024"</ItemText>
                    {/* <ItemText target="_blanck" href="https://coru.com/privacy-notice">{i13}</ItemText> */}
                </ListItems>
            </ColumnFaq>
        </Wrapper>
  );
};

export default Footer;
