import React from 'react';
import {
  TrainingText,
  Images,
  DivLoader,
} from '../../assets/styles/Training.styled';
import loader from '../../assets/img/anacoach/loading.svg';

function loadingWo() {
  return (
        <div className="topOne1">
            <DivLoader ta="left" margin="0 20px 0 0" wi="110px" he="32px" mt="8%" mtw="10%" >
                <Images
                    src={loader}
                    top="-32px"
                    topweb=""
                    left="2px"
                    leftw="0"
                    width="95px"
                    position="absolute"
                    className="img-fluid rotate180"
                ></Images>
                {/* <CircularProgress color="inherit" /> */}
            </DivLoader>
            <DivLoader bcolor="transparent" ta="left" margin="0 20px 0 0" wi="110px" he="32px">
                <TrainingText size="11px" sizew="12px" className="roboto" align="left" alignweb="left" ls="-0.2px" color="#A6A6A6">
                    Escribiendo...
                </TrainingText>
            </DivLoader>
        </div>
  );
}

export default loadingWo;
