import styled from "styled-components";
import Shape from '../Assets/Imgs/shape.svg';

export const WrapperT = styled.div`
    //padding-top: 105px;
    background: #FEFEFE;
    width: 100%;
    @media (max-width: 834px) {
        padding-top: 0px;
    }
    @media (max-width: 375px) {
        padding-top: 40.63px;
    }
`;

export const Background = styled.div`
    height: auto;
    background: linear-gradient(0.28deg, #E3ECFF 0.42%, rgba(237, 243, 255, 0) 80.41%);
    display: grid;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
   /*  background-image: url(${Shape}); */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    grid-template-columns: minmax(24px, 1fr) minmax(834px, 1062px) minmax(24px, 1fr);
    grid-template-rows: 200px 1fr 213px;
    @media (max-width: 834px) {
        grid-template-columns: minmax(24px, 1fr) minmax(375px, 834px) minmax(24px, 1fr);
        grid-template-rows: 178px 1fr 219px;
  }
    @media (max-width: 375px) {
        grid-template-columns: 16px 1fr 16px;
        grid-template-rows: 100px 1fr 40px;
  }
`;
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    grid-column-start: 2;
    grid-row-start: 2;
    width: 100%;
`;

export const BackGroundShape = styled.img`
    width: 100%;
    position: absolute;
    z-index: 1;
`;

export const Title = styled.p`
    font-weight: 400;
    font-family: Inter;
    font-size: 16px;
    line-height: 115%;
    display: flex;
    align-items: center;
    color: #F64282;
    margin: 0;
    @media (max-width: 580px) {
        font-size: 16px;
    }
`;

export const Subtitle = styled.p`
    font-weight: 600;
    font-size: 48px;
    line-height: 110%;
    display: flex;
    align-items: center;
    color: #464646;
    margin-top: 28px;
    margin-bottom: 0;
    font-family: 'Inter';
    @media (max-width: 580px) {
        font-size: 32px;
    }
`;

export const GetStartContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    margin-top: 40px;
    @media (max-width: 834px) {
        gap: 24px;
        display: block;
    }
`;

export const StartText = styled.p`
    font-weight: 900;
    font-size: 20px;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #464646;
    margin: 0;
    font-family: 'Inter';
    @media (max-width: 375px) {
        font-size: 18px;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    height: 100%;
    @media (max-width: 755px) {
      display: block;
      margin-top: 28px;
    }
`;

export const ButtonSecondary = styled.button`
    border: none;
    padding: 14px 28px;
    width: max-content;
    height: 48px;
    background: #F64282;
    border-radius: 8px;

    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    color: #fff;
    outline: none;
    text-decoration: none;
    &:hover {
        background: #F4EFFC;
        color: #6200EA;
        border: 0;
        outline: none;
    }
    @media (max-width: 960px) {
        height: 60px;
    }
    @media (max-width: 375px) {
    
    }
    @media (max-width: 834px) {
       width: 100%;
    }
`;

export const CardsContainer = styled.div`
    width: 100%;
    height: auto;
    display: ${(props) => props.display || 'flex'};
    grid-template-columns: ${(props) => props.gridTemplateColumns || ''};
    gap: 10px;
    margin: ${(props) => props.margin || 'auto'};
    @media (max-width: 540px) {
        flex-wrap: wrap;
    }
    @media (max-width: 768px) {
        grid-template-columns: ${(props) => props.gridTemplateColumnsSm || ''};
    }
`;

export const Card = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.padding || '20px 40px 40px 40px'};
    border-radius: 16px;
    height: ${(props) => props.height || '293px'};
    justify-content: flex-start;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    /* box-shadow: ${(props) => (props.isOver ? '-5px 5px 5px #32a4f0;' : 'none')}; */
    @media (max-width: 834px) {
        height: auto;
    }
`;

export const TitleCard = styled.span`
    color: #000;
    background: #fff;
    mix-blend-mode: multiply;
    position: relative;
    background: linear-gradient(350.36deg,#F64282 30.95%,#006DFF 58.03%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    -moz-background-clip: text;
    color: transparent;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    text-align: center;
    
`;

export const TextCard = styled.p`
    font-weight:${(props) => props.fontWeight || '400'};
    text-align:center;
    font-size:${(props) => props.fontSize || '20px'};
    line-height: 24px;
    display: flex;
    justify-content:${(props) => props.justifyContent || 'center'};
    align-items: center;
    letter-spacing: 1px;
    color: #464646;
    margin: 0;
    @media (max-width: 375px) {
        font-size: 16px;
    }
`;

export const TitleSecondary = styled.p`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 32px;
    line-height: 110%;
    display: flex;
    align-items: center;
    color: #6CE5FF;
    margin-top: 200px;
    margin-bottom: 0;
    @media (max-width: 834px) {
        margin-top: 180px;
  }
    @media (max-width: 375px) {
        margin-top: 100px;
  }
`;

export const Agiletext = styled.p`
    font-family: 'Inter';
    font-weight: 600;
    font-size: 52px;
    line-height: 110%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-top: 28px;
    margin-bottom: 0;
    @media (max-width: 375px) {
        font-size: 32px;
}
`;

export const EnsuringText = styled.p`
    font-family: 'Inter';
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 0;
    @media (max-width: 375px) {
        font-size: 18px;
}
`;

export const CaruselContainer = styled.div`
    width: 100%;
`;
