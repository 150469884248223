import React, {
  Fragment, useState, useEffect, useCallback,
} from 'react';
import TextField from '@material-ui/core/TextField';
import '../../../assets/styles/WelcomeValidation.css';
import '../../../assets/styles/TextCustom.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Button,
  Main,
  Col11,
  Container,
  Col3,
  Col4,
  ButtonPassword,
  ParagraphOne,
  SubtitleOne,
} from '../common.styled';
function ModalCcPassword() {
  const [open, setOpen] = useState(true);

  const [form, setForm] = useState({
    pass1: '',
    pass2: '',
    pass3: '',
    pass4: '',
  });
  const [error, setError] = useState({
    pass1: false,
    pass2: false,
    pass3: false,
    pass4: false,
  });

  const [texterror, settexterror] = useState({
    pass1: '',
    pass2: '',
    pass3: '',
    pass4: '',
  });
  const [texterrorLogin, settexterrorLogin] = useState();
  const [btn, setBtn] = useState(true);

  const handleChange = (e) => {
    e.persist();
    setForm({
      ...form,
      [e.target.name]: e.target.value === 'N' ? '' : e.target.value,
    });
    console.log(e.target.value);
    ValidaNumero(e.target.name, e.target.value);
    const { name, value } = e.target;
    switch (name) {
      case 'pass1':
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass2']").focus();
        } else {
          return false;
        }
        break;
      case 'pass2':
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass3']").focus();
        } else {
          return false;
        }
        break;
      case 'pass3':
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass4']").focus();
        } else {
          return false;
        }
        break;
      default:
        break;
    }
  };

  const ValidaNumero = (nombre, valor) => {
    if (nombre && !/^[0-9]+$/i.test(valor) && valor.length !== 1) {
      texterror[nombre] = 'Error';
      error[nombre] = true;
    } else {
      texterror[nombre] = '';
      error[nombre] = false;
    }
  };

  const ebtn = useCallback(() => {
    if (
      form.pass1.length === 1
      && !error.pass1
      && form.pass2.length === 1
      && !error.pass2
      && form.pass3.length === 1
      && !error.pass3
    ) {
      setBtn(false);
    } else {
      setBtn(true);
    }
  });
  useEffect(() => {
    ebtn();
  }, [ebtn]);
  const handleClick = (e) => {
    if (form.pass1 === '') {
      setForm({
        ...form,
        pass1: e.target.value === 'N' ? '' : e.target.value,
      });
    } else if (form.pass2 === '') {
      setForm({
        ...form,
        pass2: e.target.value === 'N' ? '' : e.target.value,
      });
    } else if (form.pass3 === '') {
      setForm({
        ...form,
        pass3: e.target.value === 'N' ? '' : e.target.value,
      });
    } else if (form.pass4 === '') {
      setForm({
        ...form,
        pass4: e.target.value === 'N' ? '' : e.target.value,
      });
    }
  };

  const guardar = (e) => {
    e.preventDefault();
    if (
      form.pass1.length === 1
      && !error.pass1
      && form.pass2.length === 1
      && !error.pass2
      && form.pass3.length === 1
      && !error.pass3
      && form.pass4.length === 1
      && !error.pass4
    ) {
      setOpen(false);
    }
  };

  return (
    <Fragment>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <DialogContent>
          <Main ta="center" background="#fff">
            <Container width="50%" mt="1rem" widthMobile="100%" mb="15%">
              <Col11>
                <form autoComplete="off" className="formheight">
                  <SubtitleOne
                    mt="7%"
                    size="28px"
                    id="idTitle"
                    className="font-weight-bold"
                    sizeMobile="20px"
                  >
                    Ingresa tu nueva contraseña
                  </SubtitleOne>
                  <Container width="80%">
                    <Col3>
                      <TextField
                        id="pass1"
                        name="pass1"
                        label=""
                        type="tel"
                        value={form.pass1}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 1);
                        }}
                        inputProps={{ className: 'text-center' }}
                      />
                    </Col3>
                    <Col3>
                      <TextField
                        id="pass2"
                        name="pass2"
                        label=""
                        type="tel"
                        value={form.pass2}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 1);
                        }}
                        inputProps={{ className: 'text-center' }}
                      />
                    </Col3>
                    <Col3>
                      <TextField
                        id="pass3"
                        name="pass3"
                        label=""
                        type="tel"
                        value={form.pass3}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 1);
                        }}
                        inputProps={{ className: 'text-center' }}
                      />
                    </Col3>
                    <Col3>
                      <TextField
                        id="pass4"
                        name="pass4"
                        label=""
                        type="tel"
                        value={form.pass4}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 1);
                        }}
                        inputProps={{ className: 'text-center' }}
                      />
                    </Col3>
                  </Container>
                  <Container>
                    <ParagraphOne fontSize="13px" mt="2%" mb="2%">
                      {texterrorLogin}
                    </ParagraphOne>
                  </Container>
                  <Container
                    mt="8%"
                    mb="8%"
                    width="50%"
                    widthMobile="80%"
                    displayweb="none"
                    displaymobile="flex"
                  >
                    <Col4>
                      <ButtonPassword
                        id="btn1"
                        type="button"
                        value="1"
                        onClick={handleClick}
                      >
                        1
                      </ButtonPassword>
                    </Col4>
                    <Col4>
                      <ButtonPassword
                        id="btn2"
                        type="button"
                        value="2"
                        onClick={handleClick}
                      >
                        2
                      </ButtonPassword>
                    </Col4>
                    <Col4>
                      <ButtonPassword
                        id="btn3"
                        type="button"
                        value="3"
                        onClick={handleClick}
                      >
                        3
                      </ButtonPassword>
                    </Col4>

                    <Col4>
                      <ButtonPassword
                        id="btn4"
                        type="button"
                        value="4"
                        onClick={handleClick}
                      >
                        4
                      </ButtonPassword>
                    </Col4>
                    <Col4>
                      <ButtonPassword
                        id="btn5"
                        type="button"
                        value="5"
                        onClick={handleClick}
                      >
                        5
                      </ButtonPassword>
                    </Col4>
                    <Col4>
                      <ButtonPassword
                        id="btn6"
                        type="button"
                        value="6"
                        onClick={handleClick}
                      >
                        6
                      </ButtonPassword>
                    </Col4>
                    <Col4>
                      <ButtonPassword
                        id="btn7"
                        type="button"
                        value="7"
                        onClick={handleClick}
                      >
                        7
                      </ButtonPassword>
                    </Col4>
                    <Col4>
                      <ButtonPassword
                        id="btn8"
                        type="button"
                        value="8"
                        onClick={handleClick}
                      >
                        8
                      </ButtonPassword>
                    </Col4>
                    <Col4>
                      <ButtonPassword
                        id="btn9"
                        type="button"
                        value="9"
                        onClick={handleClick}
                      >
                        9
                      </ButtonPassword>
                    </Col4>
                    <Col4>
                      <ButtonPassword
                        id="btn0"
                        type="button"
                        value="0"
                        onClick={handleClick}
                      >
                        0
                      </ButtonPassword>
                    </Col4>
                  </Container>
                </form>
              </Col11>
            </Container>
          </Main>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={guardar}
            disabled={btn}
            className="d-block  mt-md-4"
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default ModalCcPassword;
