import styled from "styled-components";
import ShapeGetCard from '../../LpHomeCoru/Assets/img/ShapeGetCard.svg'

export const Wrapper = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    background-image: url(${ShapeGetCard});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 20px;
`;

export const Content = styled.div`
  width: 989px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  z-index: 1;
`

export const ImageCointainer = styled.div`
    width: 100%;
    align-self: center;
    align-items: center;
      justify-content: center;
      display: flex;
    @media (max-width: 834px) {
      margin-top: 60px;
  }
`;

export const Image = styled.img`
  max-width: 544px;
  min-width: 250px;
  width: 100%;
`;


export const ButtonsContainer = styled.div`
    display: flex;
    gap: 20px;
    @media (max-width: 375px) {
        flex-direction: column;
        gap: 12px;
        justify-content: center;
        align-items: center;
  }
`;

export const ButtonPrimary = styled.button`
    border: 0;
    justify-content: center;
    align-items: center;
    padding: 14px 28px;

    width: auto;
    height: 48px;

    background: #F64282;
    border-radius: 16px;

    font-weight: 700;
    font-size: 16px;
    font-family: 'Inter';
    line-height: 110%;

    text-align: center;
    letter-spacing: 1px;

    color: #FFFFFF;
    @media (max-width: 834px) {
        height: 48px;
        max-width: 245px;
        min-width: 150px;
        width: 100%;
  }
  @media (max-width: 400px) {
        height: auto;
        max-width: none;
        min-width: none;
        width: 100%;
  }
`;


export const Title = styled.p`
    font-style: normal;
    font-family: 'Cabin';
    font-weight: 600;
    font-size: 52px;
    line-height: 110%;
    color: #464646;
    flex-grow: 0;
    margin: 0;
    @media (max-width: 400px) {
        font-size: 32px;
  }
`;

export const Description = styled.p`
    font-style: normal;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 1px;
    color: #595959;
    margin: 0;
    @media (max-width: 1440px) {
    }
`;


export const SubContentColor = styled.span`
    font-style: normal;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 1px;
    color: #595959;
    margin: 0;
    @media (max-width: 1440px) {
    }
`;

export const TitleContainer = styled.div`

`
export const DescriptionContainer = styled.div`

`
