import styled from "styled-components";

export const Wrapper = styled.div`
    height: auto;
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-columns: minmax(24px, 188px) 410px minmax(10px, 83px) 519px minmax(24px, 130px);
    grid-template-rows: 200px 242px 200px;
    @media (max-width: 1053px) {
        grid-template-columns: 24px 410px minmax(10px, 83px) minmax(300px, 519px) 24px;
    }
    @media (max-width: 834px) {
        grid-template-columns: 24px auto 24px;
        grid-template-rows: 100px auto auto 100px;
  }
    @media (max-width: 375px) {
       // grid-template-columns: 16px auto 16px;
      //  grid-template-rows: 60px auto 60px;
  }
`;

export const LetsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: auto;
    grid-column-start: 2;
    grid-row-start: 2;
    @media (max-width: 834px) {
        width: 100%;
  }
`;

export const ImageCointainer = styled.div`
    width: 100%;
    grid-column-start: 4;
    grid-row-start: 2;
    align-self: center;
    align-items: center;
      justify-content: center;
      display: flex;
    @media (max-width: 834px) {
      grid-column-start: 2;
      grid-row-start: 3;
      margin-top: 60px;
  }
`;

export const Image = styled.img`
  max-width: 410px;
  min-width: 200px;
  width: 100%;
`;

export const InfoContainer = styled.div`
    width: 100%;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-top:  ${(props) => props.top || ""};
    @media (max-width: 834px) {
       margin-top: 60px;
  }
    @media (max-width: 375px) {
        flex-direction: column;
        gap: 12px;
        justify-content: center;
        align-items: center;
  }
`;

export const ButtonPrimary = styled.button`
    border: 0;
    justify-content: center;
    align-items: center;
    padding: 14px 28px;

    width: auto;
    height: 48px;

    background: #F64282;
    border-radius: 16px;

    font-weight: 700;
    font-size: 16px;
    font-family: 'Inter';
    line-height: 110%;

    text-align: center;
    letter-spacing: 1px;

    color: #FFFFFF;
    @media (max-width: 834px) {
        height: 48px;
        max-width: 245px;
        min-width: 150px;
        width: 100%;
  }
  @media (max-width: 375px) {
        height: auto;
        max-width: unset;
        min-width: unset;
        width: 100%;
  }
`;
export const ButtonSecondary = styled.button`
    border: 0;
    padding: 14px 28px;
    font-family: 'Inter';

    width: auto;
    height: 48px;

    background: #F4EFFC;
    border-radius: 8px;

    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    color: #6200EA;
    @media (max-width: 834px) {
        height: 60px;
        max-width: 275px;
        min-width: 150px;
        width: 100%;
  }
  @media (max-width: 375px) {
        height: 48px;
        width: 100%;
        max-width: unset;
        min-width: unset;
  }
`;

export const Title = styled.p`
    font-style: normal;
    font-family: 'Cabin';
    font-weight: 600;
    font-size: 52px;
    line-height: 110%;
    color: #464646;
    flex-grow: 0;
    margin: 0;
    @media (max-width: 375px) {
        font-size: 32px;
  }
`;

export const Description = styled.p`
    font-style: normal;
    font-family: 'Inter';
    font-weight: 900;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 1px;
    color: #595959;
    margin: 0;
    margin-top: 20px;
    @media (max-width: 1440px) {
    }
`;

export const DescriptionInner = styled.span`
    font-style: normal;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 1px;
    color: #F64282;
    margin: 0;
    @media (max-width: 1440px) {
    }
`;

export const SubContentColor = styled.span`
  color: ${(props) => props.color || "#F64282"};
`;
